import { computed } from '@preact/signals-react';
import TabLabel from 'AppointmentBox/TabLabel';
import { ADD_APPOINTMENT_TABS } from 'AppointmentBox/helper';
import aptSignal from 'AppointmentBox/signal';
import { Button, Col, Empty, Row } from 'antd';
import { find, first, remove } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ICategoryUI } from 'types/category';
import { IServiceUI } from 'types/service';
import Category from './Category';
import InputSearch from './InputSearch';
import Service from './Service';
import shopSelectors from 'services/selectors/shop';
import Text from 'components/Text';
import SelectedService, { SelectedServiceEmpty } from './SelectedService';

const Label = () => {
  const activeKey = computed<string>(() => aptSignal.tabSignal.value).value;
  const services = computed(() => aptSignal.servicesSignal.value).value;
  return (
    <TabLabel
      label='Service'
      tabIndex={4}
      completed={!!services?.length}
      active={activeKey === ADD_APPOINTMENT_TABS.SERVICE}
    />
  );
};

const Children = () => {
  const categories = shopSelectors.categories();
  const [activeCategory, setActiveCategory] = useState<ICategoryUI | null>(categories[0]);

  useEffect(() => {
    setActiveCategory(first(categories) || null);
  }, [categories]);

  const services = computed(() => aptSignal.servicesSignal.value).value;
  const handleChooseService = (o: IServiceUI) => () => {
    const temp = [...services];
    if (services.find(s => s.serviceId === o.id)) {
      remove(temp, s => s.serviceId === o.id);
    } else {
      temp.push({
        serviceId: o.id,
        staffId: aptSignal.staffIdSignal.value || '',
        name: o.name,
        duration: o.duration,
      });
    }
    aptSignal.servicesSignal.value = temp;
  };

  return (
    <ServiceTabStyled>
      <InputSearch setActiveCategory={setActiveCategory} onChooseService={handleChooseService} />
      <SelectedServices onChooseService={handleChooseService} />
      <Text mb={1} />
      <Categories gutter={[16, 16]} wrap={false} style={{ marginTop: 0 }}>
        {categories?.length > 0 ? categories.map(o => (
          <Col key={o.id}>
            <Category active={activeCategory?.id === o.id} data={o.name} onClick={() => setActiveCategory(o)} />
          </Col>
        )) : <Col span={24}><Empty description={'Empty category'} /></Col>}
      </Categories>
      <Services gutter={[16, 16]}>
        {activeCategory?.services?.length ? activeCategory?.services?.map(o => (
          <Col key={o.id} span={6}>
            <Service data={o}
              active={!!services.find(s => s.serviceId === o.id)}
              onClick={handleChooseService(o)} />
          </Col>
        )) : <>
          <Col span={24}><Empty description={'Empty service'} /></Col>
        </>}
      </Services>
    </ServiceTabStyled>
  );
};
const ServiceTab = {
  Label,
  Children,
};
export default ServiceTab;

const SelectedServices = ({ onChooseService }: { onChooseService: (ser: IServiceUI) => () => void }) => {
  const allServices = shopSelectors.allServices();
  const divRef = useRef<HTMLDivElement>(null);
  const colWidthRef = useRef<number>(0);
  const services = computed(() => {
    const sers: IServiceUI[] = [];
    aptSignal.servicesSignal?.value?.map(o => {
      const item = find(allServices, s => s.id === o.serviceId);
      if (item) sers.push(item);
    });
    return sers;
  }).value;

  const scrollLeft = divRef?.current?.scrollLeft ? divRef.current?.scrollLeft : 0;

  const onScroll = (action: 'prev' | 'next') => () => {
    if (!divRef.current || !colWidthRef.current) return;

    const colWidth = colWidthRef.current;

    const left = scrollLeft + (action === 'next' ? colWidth : -colWidth);
    divRef.current.scrollBy({ left, behavior: 'smooth' });
  };

  // if (!services.length) return null;

  return (
    <>
      <Text mt={1} variant='LABEL_SECTION'>YOUR SERVICES: </Text>
      {!services?.length ? <SelectedServicesStyled>
        <Services gutter={[16, 16]} className='scroll-container__categories' ref={divRef} style={{ flex: 1 }}>
          <Col span={24} >
            <SelectedServiceEmpty />
          </Col>
        </Services>
      </SelectedServicesStyled> :
        <SelectedServicesStyled>
          <Services gutter={[16, 16]} className='scroll-container__categories' ref={divRef} style={{ flex: 1 }}>
            {
              !services?.length && (
                <Col span={24} >
                  <SelectedServiceEmpty />
                </Col>
              )
            }
            {services?.map(o => (
              <Col key={o.id} span={6} ref={ref => {
                if (!colWidthRef.current) {
                  colWidthRef.current = ref?.offsetWidth || 0;
                }
              }}>
                <SelectedService data={o} active onClick={onChooseService(o)} />
              </Col>
            ))}
          </Services>
          <Button className='action' style={{ opacity: services.length <= 4 ? 0 : 1, }} disabled={services.length <= 4} onClick={onScroll('prev')}><IconArrow rotate /></Button>
          <Button className='action' style={{ opacity: services.length <= 4 ? 0 : 1 }} disabled={services.length <= 4} onClick={onScroll('next')}><IconArrow /></Button>
        </SelectedServicesStyled>
      }
    </>
  );
};

const ServiceTabStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .label {
    white-space: nowrap;
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
 
`;

const SelectedServicesStyled = styled.div`
display: flex;
gap: 16px;
.scroll-container__categories {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 8px;
}
.action {
  height: 50px;
  width: 50px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;

const Categories = styled(Row)`
margin: 12px 0;
overflow-x: auto;
padding-bottom: 8px;
border-bottom:1px solid #CECECE;
`;

const Services = styled(Row)``;

const IconArrow = ({ rotate }: { rotate?: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" viewBox="0 0 24 17" fill="none" style={{ transform: rotate ? 'rotate(180deg)' : 'unset' }}>
    <path d="M14.9072 0.69381C15.1344 0.69381 15.3616 0.77751 15.5409 0.956862L22.7988 8.21469C23.1455 8.56144 23.1455 9.13537 22.7988 9.48212L15.5409 16.7399C15.1942 17.0867 14.6203 17.0867 14.2735 16.7399C13.9267 16.3932 13.9267 15.8192 14.2735 15.4725L20.8976 8.8484L14.2735 2.22429C13.9267 1.87754 13.9267 1.30361 14.2735 0.956862C14.4409 0.77751 14.6801 0.69381 14.9072 0.69381Z" fill="#1D2129" />
    <path d="M1.83798 7.95178L21.9614 7.95178C22.4516 7.95178 22.8582 8.35832 22.8582 8.84855C22.8582 9.33878 22.4516 9.74532 21.9614 9.74532L1.83798 9.74532C1.34774 9.74532 0.94121 9.33878 0.94121 8.84855C0.94121 8.35832 1.34774 7.95178 1.83798 7.95178Z" fill="#1D2129" />
  </svg>
);
