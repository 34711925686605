import { computed } from '@preact/signals-react';
import { Tabs, TabsProps } from 'antd';
import Modal from './Modal';
import { memo, useMemo, useState } from 'react';
import aptActions from 'services/actions/apt';
import { IPayloadBooking, IPayloadBookingV2, IPayloadBookingV2Item } from 'services/types/request';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import CustomerTab from './CustomerTab';
import ReviewTab from './ReviewTab';
import ServiceTab from './ServiceTab';
import StaffTab from './StaffTab';
import TimeTab from './TimeTab';
import { ADD_APPOINTMENT_TABS, ANYONE_NAILS_ID } from './helper';
import aptSignal from './signal';
import { loadingSignal } from 'components/Loading';
import ModalReason from './ModalReason';
import { AppointmentStatus } from 'Turn/services/constants';
import { modalConfirmSMSChangeRef } from './ModalConfirmSMSChange';
import { get, set, sumBy } from 'lodash';
import ModalNumberPad, { useModalNumberPadRef } from 'widgets/ModalNumberPad';
import ModalConfirmGroup, { useModalConfirmGroupRef } from './ModalConfirmGroup';
import { IStaffUI } from 'types/staff';
import { verifyPinCode } from 'widgets/ModalVerifyAdminPin';

const NewAppointment = () => {
  const modalNumberPadRef = useModalNumberPadRef();
  const modalConfirmGroupRef = useModalConfirmGroupRef();
  const dispatch = useAppDispatch();
  const [visibleReason, setVisibleReason] = useState<string>('');
  const activeKey = computed<string>(() => aptSignal.tabSignal.value).value;

  const isValid = computed(() => {
    const { customerSignal, staffIdSignal, timeSignal, servicesSignal, tabSignal } = aptSignal;

    return !!customerSignal.value?.id
      && !!staffIdSignal.value && !!timeSignal.value
      && !!servicesSignal.value?.length
      && tabSignal.value === ADD_APPOINTMENT_TABS.REVIEW;
  }).value;

  const items: TabsProps['items'] = useMemo(() => {
    const CUSTOMER = { key: ADD_APPOINTMENT_TABS.CUSTOMER, label: <CustomerTab.Label />, children: <CustomerTab.Children /> };
    const STAFF = { key: ADD_APPOINTMENT_TABS.STAFF, label: <StaffTab.Label />, children: <StaffTab.Children /> };
    const TIME = { key: ADD_APPOINTMENT_TABS.TIME, label: <TimeTab.Label />, children: <TimeTab.Children /> };
    const SERVICES = { key: ADD_APPOINTMENT_TABS.SERVICE, label: <ServiceTab.Label />, children: <ServiceTab.Children /> };
    const REVIEW = { key: ADD_APPOINTMENT_TABS.REVIEW, label: <ReviewTab.Label />, children: <ReviewTab.Children /> };
    if (aptSignal.detailIdSignal.value) {
      return ([STAFF, TIME, SERVICES, REVIEW]);
    }
    return ([CUSTOMER, STAFF, TIME, SERVICES, REVIEW]);
  }, [aptSignal.detailIdSignal.value]);

  const handleDone = () => {
    const values = aptSignal.getValues();

    const staffId = values.staffId?.toString();

    const objectStaff: Record<string, { serviceId: number, duration: number; }[]> = {};
    values.services.map(o => {
      const item = {
        serviceId: +o.serviceId,
        duration: o.duration,
      };
      if (get(objectStaff, o.staffId)) {
        objectStaff[o.staffId].push(item);
      } else {
        set(objectStaff, [o.staffId], [item]);
      }
    });

    const staffs: { staffId: number, serviceIds: number[], totalDuration: number }[] = Object.entries(objectStaff).map(([staffId, services]) => ({
      staffId: +staffId,
      serviceIds: services.map(o => o.serviceId),
      totalDuration: sumBy(services, o => o.duration) || 0,
    }));

    const startTimeTemp = values.time?.clone();
    const items: IPayloadBookingV2Item[] = staffs.map(o => {
      return ({
        staffId: o.staffId,
        serviceIds: o.serviceIds,
        startTime: startTimeTemp?.format('MM/DD/YYYY HH:mm') || '',
        endTime: startTimeTemp?.add(o.totalDuration, 'minute').format('MM/DD/YYYY HH:mm') || '',
      });
    });
    const customerId = values.customer?.id || '';
    const data: IPayloadBookingV2 = {
      customerId: (!customerId || customerId === '0') ? 0 : +customerId,
      customerName: values.customer?.name || '',
      bookingType: 0,
      items,
      totalPeople: values.totalPeople,
      note: values.note || '',
      isAnybody: staffId === ANYONE_NAILS_ID ? 1 : 0,
      apiKey: storage.shopId.get(),
      requestStaff: values.requestStaff,
    };

    if (!customerId || customerId === '0') {
      delete data.customerName;
    }
    dispatch(aptActions.bookAptV2(data));
  };

  const onBookingGroup = () => {
    const values = aptSignal.getValues();
    const startTime = values.time?.format('MM-DD-YYYY HH:mm') || '';
    const endTime = values.time?.clone().add(values.totalDuration, 'minute')?.format('MM-DD-YYYY HH:mm') || '';
    dispatch(aptActions.staffAvailableBooking.fetch({ startTime, endTime }));
    modalNumberPadRef.current?.open(totalPeople => {
      aptSignal.openSignal.value = false;
      modalConfirmGroupRef.current?.open(totalPeople);
    });
  };

  const onGroupDone = (staffAssigns: IStaffUI[]) => {
    const values = aptSignal.getValues();

    const startTime = values.time?.format('MM/DD/YYYY HH:mm') || '';
    const endTime = values.time?.clone().add(values.totalDuration, 'minute')?.format('MM/DD/YYYY HH:mm') || '';
    const staffId = values.staffId?.toString();
    const items: IPayloadBookingV2Item[] = staffAssigns.map(o => ({
      staffId: +o.id,
      serviceIds: values.services.map(o => +o.serviceId),
      startTime,
      endTime,
    }));
    const note = values.note || `Group: ${staffAssigns.length || 0} ${values.services.map(o => o.name).join(', ')}`;
    const data: IPayloadBookingV2 = {
      customerId: +(values.customer?.id || ''),
      customerName: values.customer?.name || '',
      bookingType: 0,
      items,
      totalPeople: values.totalPeople,
      note: note,
      isAnybody: staffId === ANYONE_NAILS_ID ? 1 : 0,
      apiKey: storage.shopId.get(),
      requestStaff: values.requestStaff,
    };
    dispatch(aptActions.bookAptV2(data));
  };

  const onModalConfirmGroupCancel = () => {
    aptSignal.openSignal.value = true;
  };

  const handleClose = () => {
    aptSignal.close();
  };

  const handleDelete = () => {
    verifyPinCode.current?.checkAdmin((pinCode) => {
      if (!aptSignal.detailIdSignal.value) return;
      dispatch(aptActions.deleteApt({ aptId: aptSignal.detailIdSignal.value, pinCode: pinCode || '' }));
    });
  };

  const handleUpdate = () => {
    if (!aptSignal.detailIdSignal.value) return;
    const values = aptSignal.getValues();

    const startTime = values.time?.format('MM/DD/YYYY HH:mm') || '';
    const endTime = values.time?.clone().add(values.totalDuration, 'minute')?.format('MM/DD/YYYY HH:mm') || '';
    const staffId = values.staffId?.toString();
    const isReUpdateWalkIn = values.customer?.id === 're-update-walk-in';
    const data: IPayloadBooking = {
      type: 'FEEDBACK/DASHBOARD/ON_EDIT_APPOITMENT',
      payload: {
        id: +aptSignal.detailIdSignal.value,
        customerId: values.customer?.id !== 're-update-walk-in' ? +(values.customer?.id || 0) : 0,
        customerName: values.customer?.name || '',
        type: 1,
        serviceIds: values.services.map(o => +o.serviceId),
        staffId: staffId === ANYONE_NAILS_ID ? null : +(values.staffId || ''),
        totalPeople: values.totalPeople,
        startTime,
        endTime,
        note: values.note,
        isAnybody: staffId === ANYONE_NAILS_ID ? 1 : 0,
        apiKey: storage.shopId.get(),
        requestStaff: values.requestStaff,
      }
    };
    if (isReUpdateWalkIn && values.customer?.phone) {
      set(data.payload, 'phone', values.customer?.phone);
    }
    verifyPinCode.current?.checkAdmin((pinCode) => {
      set(data, 'payload.pinCode', pinCode);
      dispatch(aptActions.updateApt(data));
      modalConfirmSMSChangeRef.current?.open(aptSignal.detailIdSignal.value || '');
    });
  };

  const onCancelApt = () => {
    verifyPinCode.current?.checkAdmin((pinCode) => {
      setVisibleReason(pinCode || '');
    });
  };

  const onConfirmApt = () => {
    verifyPinCode.current?.checkAdmin((pinCode) => {
      if (!aptSignal.detailIdSignal.value) return;
      dispatch(aptActions.confirmApt({ appointmentId: aptSignal.detailIdSignal.value, pinCode: pinCode || '' }));
    });
  };

  const onCheckInApt = () => {
    verifyPinCode.current?.checkAdmin(() => {
      if (!aptSignal.detailIdSignal.value) return;
      dispatch(aptActions.checkInApt(aptSignal.detailIdSignal.value));
    });
  };

  const handleCancelApt = (reason: string) => {
    if (!aptSignal.detailIdSignal.value) return;
    dispatch(aptActions.cancelApt({ aptId: aptSignal.detailIdSignal.value, reason, pinCode: visibleReason || '' }));
    setVisibleReason('');
  };


  return (
    <>
      <Modal
        title={aptSignal.detailIdSignal.value ? 'DETAIL APPOINTMENT' : 'BOOK APPOINTMENT'}
        open={aptSignal.openSignal.value}
        okText={isValid ? 'BOOK APPOINTMENT' : 'NEXT'}
        onOk={isValid ? handleDone : aptSignal.next}
        disabledOk={aptSignal.getDisabled()}
        visibleOk={!isValid}
        aptFooter={isValid}
        updateAptFooter={isValid && !!aptSignal.detailIdSignal.value}
        onCancel={handleClose}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        onCancelApt={onCancelApt}
        onConfirmApt={onConfirmApt}
        onCheckInApt={onCheckInApt}
        onGroup={isValid ? onBookingGroup : undefined}
        checkInDone={aptSignal.detailStatusSignal.value === AppointmentStatus.CHECK_IN}
        confirmed={aptSignal.detailStatusSignal.value === AppointmentStatus.CONFIRMED}
        width='90vw'
        className='modal-max-height-unset'
        loading={loadingSignal.value}
      >
        <TabsStyled
          defaultActiveKey="1"
          items={items}
          activeKey={activeKey}
          className='cs-ant-tabs'
          tabBarStyle={aptSignal.detailStatusSignal.value === AppointmentStatus.CHECK_IN ? { display: 'none' } : undefined}
          onChange={aptSignal.setActiveKey}
          // @ts-ignore
          indicatorSize={(origin: number) => origin - 16}
          centered
        />
      </Modal>
      <ModalReason visible={!!visibleReason} onClose={() => setVisibleReason('')} onConfirm={handleCancelApt} />
      <ModalNumberPad ref={modalNumberPadRef} />
      <ModalConfirmGroup
        onOk={onGroupDone} ref={modalConfirmGroupRef}
        onCancel={onModalConfirmGroupCancel}
      />
    </>
  );
};

export default memo(NewAppointment);

const TabsStyled = styled(Tabs)`
  padding-top: 1rem;
  flex-grow: 1;
  &.cs-ant-tabs.ant-tabs .ant-tabs-ink-bar {
    background: #232F3E;
  }
`;
