import React from 'react';
import { IReportOwnerCommissionResData } from 'services/types/comm';
import styled from 'styled-components';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
type Props = {
  data: IReportOwnerCommissionResData | null;
};

const SalesOwner = ({ data }: Props) => {
  return (
    <SalesContainer>
      <div className="sales-details">
        <div className="section-title">Sales</div>
        <div className="detail-list">
          <SaleItem label='Total Tickets' value={formatNumber(data?.totalTickets)} className='totalTickets' />
          <SaleItem label='Total Products' value={formatNumber(data?.totalProducts)} className='totalProducts' />
          <SaleItem label='Services’ Sales' value={formatCurrency(data?.totalService)} className='totalService' />
          <SaleItem label='Total Gift Sold' value={formatCurrency(data?.totalGiftSold)} className='totalGiftSold' />
          <SaleItem label='Total Tips' value={formatCurrency(data?.totalTip)} className='totalTip' />
          <SaleItem label='Owner’s Disc.' value={formatCurrency(data?.totalDiscountOwner)} className='totalDiscountOwner' />
          <SaleItem label='Employee’s Disc.' value={formatCurrency(data?.totalDiscountEmployee)} className='totalDiscountEmployee' />
          <SaleItem label='Total Refund / Voided' value={formatCurrency((data?.totalRefund || 0) + (data?.totalVoided || 0))} className='totalRefund + totalVoided' />
          <SaleItem label='Profit' value={formatCurrency(data?.profit)} className='profit' />
          <SaleItem label='Loss' value={formatCurrency(data?.loss)} className='loss' />
        </div>
      </div>
      <div className="gross-sales-row">
        <span>GROSS SALE</span>
        <span className="value totalService">{formatCurrency(data?.totalService)}</span>
      </div>
    </SalesContainer>
  );
};

export default SalesOwner;
const SaleItem = ({ label, value, className = '' }: { label: string, value: string, className?: string }) => <div className='detail-item'>
  <span>{label}</span>
  <div className="divide-auto"></div>
  <span className={'value ' + className}>{value}</span>
</div>;
const SalesContainer = styled.div`
  align-self: stretch;
  display: flex;
  padding-top: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  .sales-details {
    display: flex;
    padding-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid black;
    .detail-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    }
    .detail-item {
      display: flex;
      align-items: flex-end;
      align-self: stretch;
      .divide-auto {
        flex: 1;
        border-bottom: 1px dashed black;
      }
      span {
        color: black;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &.value {
          text-align: right;
          font-weight: 600;
          line-height: 20px;
          text-transform: capitalize;
        }
      }
    }
  }

  .gross-sales-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    span {
      color: black;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      &.value {
        text-align: right;
      }
    }
  }
`;
