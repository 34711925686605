import { AutoScrollActivator, DndContext, DragEndEvent, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { ICalendarItemData, ICalendarSchema, ICalendarViewRowItem, ICalendarViewRowSpanInfoItem } from 'Calendar/types';
import { set } from 'lodash';
import moment from 'moment';
import aptActions from 'services/actions/apt';
import shopSelectors from 'services/selectors/shop';
import { ICalendarDropData } from 'services/types/apt';
import { useAppDispatch } from 'store/hooks';
import { momentTimezone } from 'utils/time';
import CalendarStyled from '../styles';
import { DraggableOverlay } from './DnD/DraggableOverlay';
import RealtimeLine from './RealtimeLine';
import CalendarViewRow from './Row';
import { aptUIActions } from 'services/reducers/apt';
import { calendarSelectors } from 'services/selectors/apt';
import { verifyPinCode } from 'widgets/ModalVerifyAdminPin';
const DATE_VALUE_FORMAT = 'MM-DD-YYYY HH:mm:ss';
const dateRex = /(^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)/;

type IProps = {
  rows: ICalendarViewRowItem[];
  rowsSpanInfo: ICalendarViewRowSpanInfoItem[];
  distanceTimeline: ICalendarSchema['distanceTimeline'];
};
const CalendarViewBody = ({ rows = [], rowsSpanInfo, distanceTimeline }: IProps) => {
  const dispatch = useAppDispatch();
  const activeTimeRow = calendarSelectors.activeTimeRow();
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 500,
      tolerance: 8,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor,);

  const scheduleBooking = shopSelectors.scheduleBooking();

  const setActiveTimeRow = (value: string) => {
    dispatch(aptUIActions.activeTimeRow(value));
  };

  const onDragEnd = (event: DragEndEvent) => {

    const appointmentItem = (event.active?.data?.current?.data) as ICalendarItemData;
    if (!appointmentItem) return;
    const [timeStr, droppable] = String(event.over?.id || '').split('/');

    let timeStart = momentTimezone(appointmentItem.startTime);
    const timeEnd = momentTimezone(appointmentItem.endTime);
    const distance = timeEnd.diff(timeStart, 'minutes');
    if (dateRex.test(droppable)) {
      timeStart = moment(droppable, 'DD-MM-YYYY');
    }

    const time = moment(timeStr, 'HH:mm');
    timeStart.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: 0,
    });
    const payload: ICalendarDropData = {
      id: appointmentItem.id,
      startTime: timeStart.format(DATE_VALUE_FORMAT),
      distance,
    };

    if (!dateRex.test(droppable)) {
      set(payload, 'staffId', droppable);
    }
    verifyPinCode.current?.checkAdmin((pinCode) => {
      set(payload, 'pinCode', pinCode);
      dispatch(aptActions.calendar.updateDrop.fetch(payload));
    });
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      autoScroll={{ enabled: true, activator: AutoScrollActivator.DraggableRect }}
    >
      <CalendarStyled.TBody activeTimeRow={activeTimeRow}>
        {rows.map((row, index) => (
          <CalendarViewRow
            key={row.id}
            data={row}
            index={index}
            rowsSpanInfo={rowsSpanInfo}
            distance={distanceTimeline}
            scheduleBooking={scheduleBooking}
            setActiveTimeRow={setActiveTimeRow}
          />
        ))}
        <RealtimeLine />
      </CalendarStyled.TBody>
      <DraggableOverlay />
    </DndContext>
  );
};

export default CalendarViewBody;
