import { Row } from 'antd';
import bhSignal from 'BlockHourBox/signal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import Action, { RefreshBtn } from './Action';
import iconBack from './back.png';
import { IconApt, IconBlockHour, IconNewApt, IconQuickCommission } from './icons';
import Ontiloo from './Ontiloo';
import { dateParamSignal } from 'CalendarFilter/DatePicker';
import moment from 'moment';
import { CALENDAR_TIME_SET } from 'constants/calendar';
import aptSignal from 'AppointmentBox/signal';
import { globalQuickCommissionRef } from 'Quick-Commission';
type Props = {
  turn?: boolean;
};

const Nav = ({ turn }: Props) => {
  const navigate = useNavigate();
  const getCurrentTimeSlot = () => {
    const filterDate = dateParamSignal.value || moment();
    const now = moment().add(15, 'minute');
    const nowTime = [0, 15, 30, 45, 60].map(o => ({ key: o, value: (now.get('minute') - o) })).filter(o => o.value >= 0);
    const minute = nowTime.find(o => o.value === Math.min(...nowTime.map(o => o.value)))?.key;

    let startTime = filterDate.clone();
    if (filterDate.isSame(now, 'date')) {
      startTime = moment().set({
        hour: now.get('hour'),
        minute: minute ?? now.get('minute'),
        second: 0,
      });
      const { close, open } = CALENDAR_TIME_SET;
      if (startTime.isBetween(open, close))
        return startTime.format();
    }
    return startTime.set({ hour: 9, minute: 0, second: 0 }).format();
  };

  const handleNewBlockHour = () => bhSignal.open(getCurrentTimeSlot());

  const goBack = async () => {
    // @ts-ignore
    window?.ReactNativeWebView?.postMessage('WEB_GO_BACK');
  };

  const handleNewApt = () => aptSignal.open();

  const onQuickCommission = () => globalQuickCommissionRef.current?.open();

  if (turn) {
    return (
      <NavTurnStyled className='NavTurnStyled'>
        <button onClick={goBack}><BackButton /></button>
        <Ontiloo />
        <div style={{ flex: 1 }} />
        <Actions >
          <Action icon={IconBlockHour} title='Block hours' onClick={handleNewBlockHour} />
          <Action icon={IconApt} title='Appointment list' onClick={() => navigate(`/${storage.shopId.get()}/turn/list`)} />
          <Action icon={IconNewApt} title='Book appointment' onClick={handleNewApt} />
          <RefreshBtn />
        </Actions>
      </NavTurnStyled>
    );
  }

  return (
    <NavTurnStyled className='NavTurnStyled'>
      <button onClick={goBack}><BackButton /></button>
      <Ontiloo />
      <div style={{ flex: 1 }} />
      <Actions >
        <Action icon={IconQuickCommission} title='Quick Commission' onClick={onQuickCommission} />
        <Action icon={IconBlockHour} title='Block hours' onClick={handleNewBlockHour} />
        <Action icon={IconApt} title='Appointment list' onClick={() => navigate(`/${storage.shopId.get()}/list`)} />
        <Action icon={IconNewApt} title='Book appointment' onClick={handleNewApt} />
        <RefreshBtn />
      </Actions>
    </NavTurnStyled>
  );
};

export default Nav;

const NavTurnStyled = styled(Row)`
  background: #232F3E;
  align-items: center;
  padding: 8px 16px;
`;

const BackButton = () => (
  <BackButtonStyled>
    <img src={iconBack} />
  </BackButtonStyled>
);

const BackButtonStyled = styled.button`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
  }
  margin-right: 1.5rem;
`;

const Actions = styled(Row)`
  gap: 0rem;
  align-self: stretch;
`;
