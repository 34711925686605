import { ICalendarHeader, ICalendarItemData } from 'Calendar/types';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { styled } from 'styled-components';
import Appointment from './Appointment';
import MultiAppointment from './MultiAppointment';
import BlockHourItem from './BlockHour';
import { Popover } from 'antd';
import Icon from 'assets/Icons';
import { calendarDOM } from 'Calendar/styles';
type ICalendarItemProps = {
  data: ICalendarItemData[];
  headerData?: ICalendarHeader;
  isMonthView?: boolean;
  dateOfMonth?: string;
  disabled?: boolean;
};
const CalendarItem: React.FC<ICalendarItemProps> = ({ disabled, data, headerData, isMonthView, dateOfMonth }) => {
  const aptList = data.filter(o => o.type === 'apt');
  const blockHoursList = data.filter(o => o.type === 'block-hour');

  const contentBreakTime = useMemo(() => {
    const aptList = data.filter(o => o.type === 'apt');
    if (!aptList?.length) return null;
    return (
      <ContentStyled>
        {(aptList.length > 1 ?
          <MultiAppointment
            headerData={headerData}
            data={aptList}
            isMonthView={isMonthView}
            dateOfMonth={dateOfMonth}
            disabled={disabled}
          /> : <Appointment
            headerData={headerData}
            data={get(aptList, [0])}
            isMonthView={isMonthView}
            dateOfMonth={dateOfMonth}
            disabled={disabled}
          />)}
      </ContentStyled>
    );
  }, [data]);

  if (blockHoursList.length && aptList.length) {
    return (
      <CalendarItemStyled isMonthView={isMonthView}>
        <div className="top">
          <Popover content={contentBreakTime} trigger={['click']}
            // @ts-ignore 
            getTooltipContainer={() => calendarDOM.current}>
            <button style={{ height: '32px', width: '32px' }}><Icon type='exclamationCircle' /></button>
          </Popover>
        </div>
        <BlockHourItem
          headerData={headerData}
          data={get(blockHoursList, [0])}
          isMonthView={isMonthView}
          dateOfMonth={dateOfMonth}
          disabled={disabled}
        />

      </CalendarItemStyled>
    );
  }

  if (blockHoursList.length) {
    return (
      <CalendarItemStyled isMonthView={isMonthView}>
        <BlockHourItem
          headerData={headerData}
          data={get(blockHoursList, [0])}
          isMonthView={isMonthView}
          dateOfMonth={dateOfMonth}
          disabled={disabled}
        />
      </CalendarItemStyled>
    );
  }

  if (aptList.length)
    return (
      <CalendarItemStyled isMonthView={isMonthView}>
        {(aptList.length > 1 ?
          <MultiAppointment
            headerData={headerData}
            data={aptList}
            isMonthView={isMonthView}
            dateOfMonth={dateOfMonth}
            disabled={disabled}
          /> : <Appointment
            headerData={headerData}
            data={get(aptList, [0])}
            isMonthView={isMonthView}
            dateOfMonth={dateOfMonth}
            disabled={disabled}
          />)}
      </CalendarItemStyled>
    );
  return null;
};

export default CalendarItem;
type CalendarItemStyledProps = {
  isMonthView?: boolean;
};
const CalendarItemStyled = styled.div<CalendarItemStyledProps>`
  height: 100%;
  width: 100%;
  position:relative;
  .top {
    position: absolute;
    top: -15px;
    right:0px;
    z-index: 1;
    svg {
      transform: scale(1.3);
    }
  }
`;

const ContentStyled = styled.div`
  display:flex;
  flex-direction: column;
  gap:12px;
  height: 5rem;
  width: 15rem;
  margin-bottom: 1rem;
`;