import { get } from 'lodash';
import { IStateCalendar, IStateTable } from 'services/types/apt';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
type MyState = RootState['apt'];

const getCurrentState = (state: RootState): MyState => state['apt'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

export const calendarSelectors = {
  distanceTimeline: () => selector('calendar.distanceTimeLine') as IStateCalendar['distanceTimeLine'],
  typeView: () => selector('calendar.typeView') as IStateCalendar['typeView'],
  dateRanges: () => selector('calendar.dateRanges') as IStateCalendar['dateRanges'],
  data: () => {
    const calendar = selector('calendar') as IStateCalendar;
    return [...calendar.data, ...calendar.blockHours];
  },
  params: () => selector('calendar.params') as IStateCalendar['params'],
  activeTimeRow: () => selector('activeTimeRow') as MyState['activeTimeRow'],
};

export const tableSelectors = {
  getPureParams: (state: RootState) => state['apt']?.table?.params || {},
  data: () => selector('table.data') as IStateTable['data'],
  blockHours: () => selector('table.blockHours') as IStateTable['blockHours'],
  params: () => selector('table.params') as IStateTable['params'],
  total: () => selector('table.total') as IStateTable['total'],
};

const quickBooking = {
  services: () => selector('quickBooking.services') as MyState['quickBooking']['services'],
};
const staffAvailableBooking = ()=> selector('staffAvailableBooking') as MyState['staffAvailableBooking'];
const staffAvailableBookingLoading = ()=> selector('staffAvailableBookingLoading') as MyState['staffAvailableBookingLoading'];

const aptSelectors = {
  calendar: calendarSelectors,
  table: tableSelectors,
  quickBooking,
  staffAvailableBooking,
  staffAvailableBookingLoading,
};
export default aptSelectors;
