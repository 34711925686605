import { Form, FormListFieldData, Select, Table, TableProps } from 'antd';
import CurrencyInput from 'components/CurrencyInput';
import { emptyLocaleTable } from 'Quick-Commission/EmptyTable';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import delete_icon from './icons/delete.svg';

const deleteIcon = <img src={delete_icon} alt="delete_icon" style={{ width: 32, height: 32 }} />;
export enum QuickCommissionPaymentMethods {
  CREDIT_CARD = 1,
  CASH = 0,
  GIFT_CARD = 2,
  OTHER = 3,
}
export const payments = [
  {
    label: 'Cash',
    value: QuickCommissionPaymentMethods.CASH,
  },
  {
    label: 'Credit Card',
    value: QuickCommissionPaymentMethods.CREDIT_CARD,
  },
  {
    label: 'Gift Card',
    value: QuickCommissionPaymentMethods.GIFT_CARD,
  },
];

export type IQuickCommissionPaymentItem = {
  type: QuickCommissionPaymentMethods;
  last4Digits?: number;
  amount?: number;
};
export type IQuickCommissionTicketItem = {
  id: string;
  ticketNumber: number;
  paymentMethod: QuickCommissionPaymentMethods,
  sale: number;
  tip: number;
  discount: number;
  supply: number;
  note: string;
};
type Props = {
  tickets: FormListFieldData[];
  remove: (index: number | number[]) => void;
};
const TicketTableContent = ({ tickets, remove }: Props) => {
  const columns: TableProps<FormListFieldData>['columns'] = [
    {
      title: 'Ticket',
      key: 'ticketNumber',
      width: 100,
      render: (record: FormListFieldData, data, index) => <TicketNumber><span>#{index + 1}</span></TicketNumber>,
    },
    {
      title: 'Sub Total',
      key: 'sale',
      render: (record: FormListFieldData, data, index) => <PassValueForm
        name={[record.name, 'sale']}
        render={(val, onChange = () => undefined) => <CurrencyInput autoFocus={!index} size='in-table' value={val} onChange={_val => onChange(+_val.target.value)} />}
      />
    },
    {
      title: 'Tip',
      key: 'tip',
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'tip']}
        render={(val, onChange = () => undefined) => <CurrencyInput size='in-table' value={val} onChange={_val => onChange(+_val.target.value)} />}
      />
    },
    {
      title: 'Discount',
      key: 'discount',
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'discount']}
        render={(val, onChange = () => undefined) => <CurrencyInput size='in-table' value={val} onChange={_val => onChange(+_val.target.value)} />}
      />


    },
    {
      title: 'Supply',
      key: 'note',
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'supply']}
        render={(val, onChange = () => undefined) => <CurrencyInput size='in-table' value={val} onChange={_val => onChange(+_val.target.value)} />}
      />
    },

    {
      title: 'Payment Method',
      key: 'paymentMethod',
      width: 180,
      render: (record: FormListFieldData) => <PassValueForm
        name={[record.name, 'paymentMethod']}
        render={(val, onChange) => <Select style={{ height: '2rem', width: '100%' }} value={val} onChange={onChange} options={payments} />}
      />
    },
    {
      title: 'Total',
      key: 'note',
      width: 150,
      render: (record: FormListFieldData) => (
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const val: IQuickCommissionTicketItem = getFieldValue(['tickets', record.name]);
            return (
              <div style={{ fontSize: 18, fontWeight: '600', textAlign: 'right' }}>
                {formatCurrency(val.sale + val.tip - val.discount + val.supply)}
              </div>
            );
          }}
        </Form.Item>
      )
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',
      render: (record: FormListFieldData) => {
        return <button onClick={() => remove(record.name)} type='button' className='btn-delete'>{deleteIcon}</button>;
      },
    },
  ];
  return (
    <TableStyled
      key={'key'}
      dataSource={tickets}
      scroll={{ y: '40vh' }}
      // @ts-ignore
      columns={columns}
      pagination={false}
      locale={emptyLocaleTable}
    />
  );
};

export default TicketTableContent;

const TicketNumber = styled.div`
  background: #1D2129;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  span {
    color: #fff;
  }
`;
const TableStyled = styled(Table)`
&.ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-thead>tr>th{
  border-bottom: 1px solid #CCD4DC;
  border-right: 1px solid #FFF;
  background: #484F58;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.ant-table-tbody>tr>td.ant-table-cell {
  padding: 0px 16px;

  .btn-delete {
    height: 48px;
    width: 48px;
    margin: auto;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      background: #eee;
    }
  }
}
`;

const ReceiveValueForm = ({ value, render, onChange }: { onChange?: (value: any) => void, value?: any, render?: (value: any, onChange?: (value: any) => void) => any }) => render ? render(value, onChange) : value;
const PassValueForm = ({ name, render }: { name?: any, render?: (value: any, onChange?: (value: any) => void) => any }) => <Form.Item noStyle name={name}><ReceiveValueForm render={render} /></Form.Item>;

