import { Avatar, Carousel, Form, FormInstance } from 'antd';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import check_icon from './icons/check.svg';
import next_icon from './icons/next.svg';
import prev_icon from './icons/prev.svg';
import staff_icon from './icons/staff-icon.svg';
import { useMemo } from 'react';
const staffIcon = <img src={staff_icon} alt="staff_icon" style={{ width: 58, height: 58 }} />;
const prevArrow = <img src={prev_icon} alt="prev_icon" style={{ width: 32, height: 32 }} />;
const nextArrow = <img src={next_icon} alt="next_icon" style={{ width: 32, height: 32 }} />;
const checkIcon = <img src={check_icon} alt="check_icon" style={{ width: 32, height: 32 }} />;

type Props = {
  value?: IStaffUI;
  onChange?: (value: IStaffUI) => void;
  form: FormInstance<any>;
};
const StaffCarousel = ({ form, value: staffSelected, onChange = () => undefined }: Props) => {
  const staffs = shopSelectors.staff();
  const staffSearch: string | null | undefined = Form.useWatch('staffSearch', form);

  const filterStaffs = useMemo(() => {
    const searchStr = staffSearch?.trim().toLowerCase();
    if (!searchStr) return staffs;
    const result = staffs.filter(o => !!o.name.toLowerCase()?.includes(searchStr));
    if (result?.length === 0) return staffs;
    return result;
  }, [staffs, staffSearch]);

  return (
    <div style={{ alignSelf: 'stretch', padding: '0 32px' }}>
      <CarouselWrapper
        arrows
        prevArrow={prevArrow}
        nextArrow={nextArrow}
        dots={false}
        slidesToShow={9}
        slidesToScroll={9}
        swipeToSlide
        draggable
        infinite={false}
      >
        {filterStaffs?.map((staff) => {
          const selected = staff.id === staffSelected?.id;
          return (
            <StaffItem type='button' onClick={() => onChange(staff)} key={staff.id} className={selected ? 'selected' : ''}>
              <Avatar
                size={100}
                src={staff.avatar}
                icon={staffIcon}
                style={{ margin: 'auto', backgroundColor: '#e74c3c', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
              />
              <StaffName className={selected ? 'selected' : ''}>{staff.name}</StaffName>
              {!!selected && <div className='check-icon'>{checkIcon}</div>}
            </StaffItem>
          );
        })}
      </CarouselWrapper>
    </div>
  );
};

export default StaffCarousel;

const CarouselWrapper = styled(Carousel)`
  .slick-prev,
  .slick-next {
    z-index: 1;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 2px solid #CCD4DC;
    background: #F1F1F1;

    &:hover {
      background: #F1F1F1;
      opacity: 0.8;
    }

    &.slick-disabled {
      opacity: 0.5;
    }
  }
`;

const StaffItem = styled.button`
  text-align: center;
  display: flex;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  background: transparent;
  position: relative;
  &.selected {
    background: #1D2129;
  }

  .check-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 99;
    border-radius: 40px;
    background: #148CF1;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StaffName = styled.p`
  font-size: 14px;
  margin-top: 8px;
  padding: 0 8px;
  color: #2C333A;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &.selected {
    color: #fff;
  }
`;
