import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Modal as AntModal, Avatar, Row } from 'antd';
import styled from 'styled-components';
import Text from 'components/Text';
import shopSelectors from 'services/selectors/shop';
import { SortableList } from './SortableList';
import apis from 'services/apis';
import { useParams } from 'react-router-dom';
import { loadingPageSignal } from 'components/LoadingPage';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store/hooks';
import shopActions from 'services/actions/shop';
import aptSelectors from 'services/selectors/apt';
import { IStaffResItem } from 'services/types/response';
import storage from 'utils/sessionStorage';
import { dateParamSignal } from 'CalendarFilter/DatePicker';
import { IStaffUI } from 'types/staff';
type Props = {};
type Ref = {
  open: () => void;
  close: () => void;
};
export const useModalReorderStaffRef = () => useRef<Ref>(null);
const ModalReorderStaff = forwardRef<Ref, Props>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const { shop_id = '' } = useParams();
  const [visible, setVisible] = useState(false);
  const handleClose = () => setVisible(false);
  const appointments = aptSelectors.calendar.data();

  const staffs = shopSelectors.staffsAvailable();
  const [items, setItems] = useState<{ id: string, name: string, avatar: string, appointments: number }[]>([]);

  const handleOpen = () => {
    setItems(staffs?.map(o => ({ id: o.id, name: o.name, avatar: o.avatar, appointments: appointments.filter(a => a.staffId === o.id).length || 0 })) || []);
    setVisible(true);
  };
  const handleDone = async () => {
    setVisible(false);
    loadingPageSignal.value = true;
    try {
      const res: AxiosResponse<{ payload: boolean }> = await apis.sortColumnBooking(shop_id, items.map(o => +o.id));
      if (res.data.payload) {
        const res2: AxiosResponse<{ payload: IStaffResItem[] }> = await apis.getStaffsAvailable(storage.shopId.get(), dateParamSignal.value?.format('YYYY-MM-DD') || '');
        const staffRes = res2?.data?.payload;
        if (staffRes) {
          const data: IStaffUI[] = staffRes.map(o => ({
            avatar: o.urlImage,
            extraData: o,
            id: o.id?.toString(),
            name: o.firstName,
          }));
          dispatch(shopActions.staffAvailable.success(data));
        }
      }
    } catch (error) {
    } finally {
      loadingPageSignal.value = false;
    }

  };

  useImperativeHandle(ref, () => ({
    open: () => handleOpen(),
    close: () => setVisible(false),
  }));

  return (
    <AntModal
      open={visible}
      onCancel={handleClose}
      centered
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={'45vw'}

    >
      <Header justify={'space-between'}>
        <Text className="title">Reorder Staffs</Text>
      </Header>
      <ContainerContent height={'80vh'}>
        <Content height={'80vh'}>
          <SortableList
            items={items}
            onChange={setItems}
            renderItem={(item) => (
              <SortableList.Item id={item.id}>
                <CateWrap>
                  <Avatar
                    src={item.avatar}
                  >
                    {item.name?.[0] || 'A'}
                  </Avatar>
                  <Text style={{ flex: 1 }} variant="H7" color="text_3">
                    {item.name}
                  </Text>
                  <Text variant="H7" color="text_3">
                    Appointments: {item.appointments}
                  </Text>
                </CateWrap>
              </SortableList.Item>
            )}
          />
        </Content>
        <Footer>
          <button className='btn btn-delete' onClick={handleClose}>
            <Text className="label">ClOSE</Text>
          </button>
          <button className='btn btn-update' onClick={handleDone}>
            <Text className="label">{'UPDATE'}</Text>
          </button>
        </Footer>
      </ContainerContent>
    </AntModal>
  );
});
ModalReorderStaff.displayName = 'ModalReorderStaff';
export default ModalReorderStaff;

const CateWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  .label {
    font-size: 18px;
    font-weight: 600;
  }
`;

const Header = styled(Row)`
  height: 50px;
  position: relative;
  align-items: center;
  padding: 8px 8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #148CF1;
  background: #E8F3FF;

  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }
  button {
    background: #fff;
    align-self: stretch;
    border-radius: 5px;
    width: 80px;
    &:hover {
      background: #FFEFEB;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  .text-action {
    color: #F05326;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
`;

const ContainerContent = styled.div<{ height?: string }>`
  max-height: 70vh;
  min-height: 70vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #F8F9FA;
  .spinning-wrapper {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    justify-content: center;
  }
`;
const Content = styled.div<{ height?: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const Footer = styled(Row)`
position: sticky;
padding: 0.75rem 1.5rem;
margin-top: 1rem;
gap: 1rem;
bottom: 0;
right: 0;
left: 0;
background-color: #fff;
.btn {
  display: flex;
  height: 48px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  .label {
    text-align: center;

    /* Body 1 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  
}
  .btn-delete, .btn-close {
    border-radius: 2px;
    border: 1px solid #7B7B7B;
    background: #7B7B7B;
    .label {
      color: #fff;
    }
  }

  .btn-update {
    border-radius: 2px;
    background: #232F3E;
    .label {
      color: #FFF;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
  .btn-delete {
    background: #fff;
    .label {
      color: #7B7B7B;
    }
  }
  .btn-confirm {
    border-radius: 2px;
    background: #0085E7;
    .label {
      color: #FFF;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
`;