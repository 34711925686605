import { Modal as AntModal, InputNumber, ModalProps, Row, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import Text from 'components/Text';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import apis from 'services/apis';
import styled from 'styled-components';
import { PassCodeVerifyRef } from 'Turn/TurnModals/PassCodeVerify';
import storage from 'utils/sessionStorage';

interface Props extends ModalProps {
  onPassPin?: (_pin: string) => void;
  onCancel?: () => void;
}
export const verifyPinCode = React.createRef<PassCodeVerifyRef>();
const ModalVerifyAdminPin = ({ onPassPin = () => undefined, onCancel = () => undefined, ...rest }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [pin, setPin] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const handleClose = () => {
    setPin('');
    setError('');
    onCancel();
  };

  useEffect(() => {
    if (rest.open) {
      setPin('');
      setError('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [rest.open]);

  const handle = useCallback(async (_pin: string) => {
    if (_pin.length < 4) return;
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: any }> = await apis.checkPinCode(storage.shopId.get(), _pin);
      const resData = res.data.payload;
      if (resData) {
        return onPassPin(_pin);
      }
      return setError('Password incorrect!');

    } catch (error) {
      setError('Password incorrect!');
    } finally {
      setLoading(false);
    }
  }, []);

  const _debounce = useCallback(debounce((_pin) => handle(_pin), 500), []);

  useEffect(() => {
    _debounce(pin);
  }, [pin]);

  return (
    <AntModal
      {...rest}
      centered
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={'40vw'}
    >
      <Header justify={'space-between'}>
        <button onClick={handleClose}><p className="text-action">CLOSE</p></button>
        <Text className="title">YOUR PIN</Text>
      </Header>
      <ContainerContent>
        <Spin spinning={loading}>
          <Content>
            <InputWrapStyled>
              <InputNumber
                ref={inputRef}
                value={pin}
                autoFocus
                onKeyPress={(event) => {
                  // @ts-ignore
                  if (event.target?.value?.length > 3) event.preventDefault();

                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                type='number'
                className='password-input'
                size='large'
                onChange={(e) => {
                  setPin(e?.toString() || '');
                  setError('');
                }}
                maxLength={4}
              />
            </InputWrapStyled>
            {error && <InvalidText>{error}</InvalidText>}
          </Content>
        </Spin>
      </ContainerContent>
    </AntModal>
  );
};

export default ModalVerifyAdminPin;
const Header = styled(Row)`
  height: 50px;
  position: relative;
  align-items: center;
  background: #fff;
  padding: 8px 8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #CECECE;

  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }
  button {
    background: #fff;
    align-self: stretch;
    border-radius: 5px;
    width: 80px;
    &:hover {
      background: #FFEFEB;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  .text-action {
    color: #F05326;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
`;

const ContainerContent = styled.div<{ height?: string }>`
  max-height: auto;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ height?: string }>`
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const InputWrapStyled = styled.div`
.ant-input-number {
  width: 100%;
  vertical-align: middle;
  &.ant-input-number-lg {
    line-height: 3;
    vertical-align: middle;
    .ant-input-number-input {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
    }
  }
}



.password-input {
  -webkit-text-security: disc;
  text-security: disc;
}
`;

const InvalidText = styled.p`
  margin-top: 1rem;
  color: #F05326;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
`;