import clsx from 'clsx';
import React from 'react';
import { IReportOwnerCommissionResData } from 'services/types/comm';
import styled from 'styled-components';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
type Props = {
  data: IReportOwnerCommissionResData | null;
};

const PaymentOwner = ({ data }: Props) => {
  return (
    <Container>
      <div className="section-title" style={{ marginBottom: 8 }}>Payments</div>
      <Details>
        <CreditCardSalesGroup>
          <SaleItem label='Credit Card Net Total' value={formatCurrency(data?.creditCard)} className='creditCard' />
          <div className="details-list">
            <SaleItem child label='C.D Sales' value={formatCurrency(data?.totalSalesCC)} className='totalSalesCC' />
            <SaleItem child label='Total Tips' value={formatCurrency(data?.totalTip)} className='totalTip' />
            <SaleItem child label='Total C.D Fees' value={formatCurrency((data?.totalFees || 0) + (data?.totalCashDiscountTip || 0))} className='totalFees + totalCashDiscountTip' />
            <SaleItem child label='Total C.D Tip Fees (Owner)' value={formatCurrency(data?.totalCashDiscountTip)} className='totalCashDiscountTip' />
            <SaleItem child label='Refund / Voided' value={formatCurrency(data?.totalRefund)} className='totalRefund' />
          </div>
        </CreditCardSalesGroup>
        <SaleItem label='Cash' value={formatCurrency(data?.cash)} className='cash' />
        <SaleItem label='Gift Card' value={formatCurrency(data?.gitCard)} className='gitCard' />
        <SaleItem label='Check / Other' value={formatCurrency(data?.checkOther)} className='checkOther' />
        <SaleItem label='Reward Point' value={formatNumber(data?.rewardPoint)} className='rewardPoint' />
      </Details>
      <Footer>
        <span>TOTAL (NET)</span>
        <span className="value total">{formatCurrency(data?.total)}</span>
      </Footer>
    </Container>
  );
};

export default PaymentOwner;
const SaleItem = ({ label, value, child, className = '' }: { label: string, value: string, child?: boolean, className?: string; }) => <div className={clsx('detail-item', child && 'child')}>
  <span>{label}</span>
  <div className="divide-auto"></div>
  <span className={'value ' + className}>{value}</span>
</div>;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;
`;

const Details = styled.div`
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-bottom: 1px solid black;

  .detail-item {
    display: flex;
    align-items: flex-end;
    align-self: stretch;
    .divide-auto {
      flex: 1;
      border-bottom: 1px dashed black;
    }
    &.child {
      padding-left: 16px;
      span {
        font-size: 14px;
      }
    }
    span {
      color: black;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &.value {
        text-align: right;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
      }
    }
  }
`;

const CreditCardSalesGroup = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: flex-start;
gap: 8px;
align-self: stretch;
.top-title {
  display: flex;
  align-items: flex-end;
  align-self: stretch;
  span {
    color: black;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &.value {
      text-align: right;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
    }
  }
}

.details-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  span {
    color: black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
    &.value {
      text-align: right;
    }
  }
`;