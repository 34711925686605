import { computed } from '@preact/signals-react';
import TabLabel from 'AppointmentBox/TabLabel';
import { ADD_APPOINTMENT_TABS } from 'AppointmentBox/helper';
import aptSignal from 'AppointmentBox/signal';
import { InputRef, Row } from 'antd';
import styled from 'styled-components';
import { ICustomerUI } from 'types/customer';
import Customer from './Customer';
import InputSearch from './InputSearch';
import shopSelectors from 'services/selectors/shop';
import { useEffect, useRef, useState } from 'react';
import InputSearchByName from './InputSearchByName';
import MyModal from 'components/Modal';

const Label = () => {
  const activeKey = computed<string>(() => aptSignal.tabSignal.value).value;
  const customer = computed(() => aptSignal.customerSignal.value).value;
  return (
    <TabLabel
      label='Customer'
      tabIndex={1}
      completed={!!customer?.id}
      active={activeKey === ADD_APPOINTMENT_TABS.CUSTOMER}
    />
  );
};
enum ESearchBy {
  PHONE = 'PHONE',
  NAME = 'NAME'
}
const Children = () => {
  const customer = computed(() => aptSignal.customerSignal.value).value;
  const customers = shopSelectors.customers();
  const inputRef = useRef<InputRef>(null);
  const handleChooseCustomer = (o: ICustomerUI) => () => {
    aptSignal.customerSignal.value = o;
    aptSignal.next();
  };
  useEffect(() => {
    if (!aptSignal.openSignal.value) return;
    setTimeout(() => inputRef.current?.focus(), 100);
  }, [aptSignal.openSignal.value]);

  return (
    <CustomerTabStyled>
      <InputSearchBox inputPhoneRef={inputRef} />
      <Customers wrap>
        {customers.map(o => (
          <CustomerWrap key={o.id}>
            <div className='box'>
              <Customer
                active={o.id === customer?.id}
                data={o}
                onClick={handleChooseCustomer(o)}
              />
            </div>
          </CustomerWrap>
        ))}
      </Customers>
    </CustomerTabStyled>
  );
};

const InputSearchBox = ({ inputPhoneRef, onSelect }: { inputPhoneRef: React.RefObject<InputRef>, onSelect?: (o: ICustomerUI) => void; }) => {
  const [searchBy, setSearchBy] = useState<ESearchBy>(ESearchBy.PHONE);
  const inputRef = useRef<InputRef>(null);
  const handleChooseCustomer = (o: ICustomerUI) => () => {
    if (onSelect) return onSelect(o);
    aptSignal.customerSignal.value = o;
    aptSignal.next();
  };
  const onChangeSearchBy = (val: ESearchBy) => {
    setSearchBy(val);
    setTimeout(() => {
      if (val === ESearchBy.NAME)
        inputRef.current?.focus();
      else
        inputPhoneRef.current?.focus();
    }, 100);
  };

  return (
    <SearchContainer>
      <div className="tags">
        <span>Search By: </span>
        <button onClick={() => onChangeSearchBy(ESearchBy.PHONE)} className={`tag-item ${searchBy === ESearchBy.PHONE ? 'active' : ''}`}>Phone</button>
        <button onClick={() => onChangeSearchBy(ESearchBy.NAME)} className={`tag-item ${searchBy === ESearchBy.NAME ? 'active' : ''}`}>Name</button>
        <div style={{ flex: 1 }} />
        <button onClick={handleChooseCustomer({ id: '0', name: 'Walk-In', phone: '0000000000' })} className={'tag-item walk-in'}>Walk-In</button>
      </div>
      {searchBy === ESearchBy.PHONE && <InputSearch inputRef={inputPhoneRef} onSelect={(o) => handleChooseCustomer(o)()} />}
      {searchBy === ESearchBy.NAME && <InputSearchByName inputRef={inputRef} onSelect={(o) => handleChooseCustomer(o)()} />}
    </SearchContainer>
  );
};

const WalkInModal = ({ onSelect = () => undefined }: { onSelect?: (o: ICustomerUI) => void }) => {
  const inputRef = useRef<InputRef>(null);
  const customers = shopSelectors.customers();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => inputRef.current?.focus(), 100);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleChooseCustomer = (o: ICustomerUI) => {
    setOpen(false);
    onSelect(o);
  };

  return <>
    <ButtonChange onClick={handleOpen}><Change /></ButtonChange>
    <MyModal
      open={open}
      onCancel={handleClose}
      title="EDIT CUSTOMER"
      onOk={handleOk}
    >
      <CustomerTabStyled style={{ padding: 16 }}>
        <InputSearchBox inputPhoneRef={inputRef} onSelect={handleChooseCustomer} />
        <Customers wrap>
          {customers.map(o => (
            <CustomerWrap key={o.id}>
              <div className='box'>
                <Customer
                  active={false}
                  data={o}
                  onClick={() => handleChooseCustomer(o)}
                />
              </div>
            </CustomerWrap>
          ))}
        </Customers>
      </CustomerTabStyled>
    </MyModal>
  </>;
};

const CustomerTab = {
  Label,
  Children,
  WalkInModal,
};

export default CustomerTab;
const CustomerTabStyled = styled.div`
  height: 100%;
`;

const Customers = styled(Row)`
  display: flex;
  margin: -6px;
  margin-top: 12px;
`;

const CustomerWrap = styled.div`
  width: 20%;
  .box {
    margin: 6px;
  }
`;

const SearchContainer = styled.div`
  .tags {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #1D2129;
    margin-bottom: 8px;
    gap: 8px;
    display: flex;
    align-items: center;
    button {
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #7B7B7B;
      padding: 4px 16px;
      border-radius: 2px;
      border: 1px solid #7B7B7B;
      background: #EEE;
      border-radius: 8px;
      &.active {
        background: #232F3E;
        border-color: #232F3E;
        color: #fff;
      }

      &.walk-in {
        background: #F05326;
        border-color: #F05326;
        color: #fff;
      }
    }
  }
`;

const ButtonChange = styled.button`
  display: flex;
  height: 30px;
  width: 40px;
  margin-left: 4px;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #e9e8e8;
  }
`;

const Change = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.69816 6.69489C7.81664 5.57642 9.19776 4.88621 10.6424 4.62156L10.282 2.6543C8.45093 2.98975 6.69852 3.86611 5.28395 5.28068C1.57397 8.99066 1.57397 15.0057 5.28395 18.7157L3.57684 20.4228H8.40527V15.5944L6.69816 17.3015C3.76923 14.3726 3.76923 9.62383 6.69816 6.69489ZM20.4243 3.57535H15.5959V8.40377L17.303 6.69667C20.2319 9.6256 20.2319 14.3743 17.303 17.3033C16.1845 18.4217 14.8034 19.1119 13.3587 19.3766L13.7191 21.3439C15.5502 21.0084 17.3026 20.132 18.7172 18.7175C22.4272 15.0075 22.4272 8.99243 18.7172 5.28245L20.4243 3.57535Z" fill="#148CF1" />
    </svg>
  );
};
