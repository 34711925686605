import ModalDark from 'components/ModalDark';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import StaffCarousel from './StaffCarousel';
import { Form } from 'antd';
import FormInputs from './FormInputs';
import TicketTableContent, { IQuickCommissionTicketItem } from './TicketTableContent';
import { first, sumBy } from 'lodash';
import { formatCurrency } from 'utils/formatCurrency';
import shopSelectors from 'services/selectors/shop';
import apis from 'services/apis';
import { IApiAddCommissionBody } from 'services/types/comm';
import { IStaffUI } from 'types/staff';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store/hooks';
import commActions from 'services/actions/comm';
import moment from 'moment';

interface IFormValues {
  staff: IStaffUI;
  tickets: IFormValueTicketItem[];
}

export interface IFormValueTicketItem {
  id: string;
  ticketNumber: number;
  paymentMethod: number;
  sale: number;
  tip: number;
  discount: number;
  supply: number;
  note: string;
}

type Props = {
  activeStaff: {
    staffId: string;
    staffName: string;
    position: string;
  } | null;
};
type Ref = {
  open: () => void;
};
export const useAddNewQuickCommissionRef = () => useRef<Ref>(null);
const AddNewQuickCommission = forwardRef<Ref, Props>(({ activeStaff }, ref) => {
  const dispatch = useAppDispatch();
  const staffs = shopSelectors.staff();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const Footer = () => {
    return <>
      <button onClick={onClose} type='button' className="btn"><span>Close</span></button>
      <button onClick={() => form.resetFields(['tickets'])} type='button' className="btn btn-submit outline"><span>Reset</span></button>
      <button onClick={() => form.submit()} type='button' className="btn btn-submit"><span>save</span></button>
    </>;
  };

  const handleFinish = async (values: any) => {
    if (!activeStaff) return null;
    const data = values as IFormValues;
    const body: IApiAddCommissionBody = {
      items: data.tickets.map((o) => ({
        discount: o.discount,
        paymentType: o.paymentMethod,
        sales: o.sale,
        supply: o.supply,
        tip: o.tip,
        total: o.sale + o.tip - o.discount + o.supply,
      })),
      staffId: +data.staff.id,
      staffAddTicket: +activeStaff?.staffId,
    };
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: any }> = await apis.addCommissions(body);
      if (res.data.payload) {
        const date = moment().format('MM-DD-YYYY');
        dispatch(commActions.setParams({ startDate: date, endDate: date }));
        onClose();
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      form.resetFields();
      if (activeStaff?.position === 'Technician') {
        const staff = staffs.find(o => o.id === activeStaff?.staffId?.toString());
        form.setFieldValue('staff', staff);
        form.setFieldValue('staffSearch', activeStaff?.staffName);
      } else {
        form.setFieldValue('staff', first(staffs));
      }
      setOpen(true);
    },
  }));
  return (
    <ModalDark loading={loading} FooterComponent={Footer} title='add quick commission' width={'90vw'} open={open} onClose={onClose} >
      <Container form={form} layout='vertical' initialValues={{ tickets: [] }} onFinish={handleFinish}>
        <TopContainer>
          {activeStaff?.position !== 'Technician' && <Form.Item noStyle name={'staff'}>
            <StaffCarousel form={form} />
          </Form.Item>}
          <Form.Item noStyle name={'staff'}>
            <FormInputs activeStaff={activeStaff} form={form} />
          </Form.Item>
        </TopContainer>
        <div style={{ alignSelf: 'stretch' }}>
          <Form.List name={'tickets'}>
            {(tickets, { remove }) => {
              return (
                <TicketTableContentStyled>
                  <TicketTableContent tickets={tickets} remove={remove} />
                </TicketTableContentStyled>
              );
            }}
          </Form.List>
          <Form.Item noStyle name={'tickets'}>
            <TotalTicket />
          </Form.Item>
        </div>
      </Container>
    </ModalDark>
  );
});

AddNewQuickCommission.displayName = 'AddNewQuickCommission';
export default AddNewQuickCommission;
const TicketTableContentStyled = styled.div`
  align-self: stretch;
`;
const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px; 
  align-self: stretch;
  background: #F8F9FE;
`;
const TopContainer = styled.div`
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: #D4D7E4;
`;
type TotalTicketProps = {
  value?: IQuickCommissionTicketItem[];
};
const TotalTicket = ({ value }: TotalTicketProps) => {
  return (
    <TotalTicketStyled>
      <span>Total: </span>
      <span>{formatCurrency(sumBy(value, val => (val.sale + val.tip - val.discount + val.supply)))}</span>
    </TotalTicketStyled>
  );
};

const TotalTicketStyled = styled.div`
height: 65px;
background: #fff;
align-self: stretch;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 16px;
span {
  color: #2C333A;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;