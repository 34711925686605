import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import iconDown from './icons/down.svg';
import { IStaffUI } from 'types/staff';
import { Avatar, Flex } from 'antd';
type Props = {
  name: string;
  price: number;
  onClearService: () => void;
  staff?: IStaffUI | null;
  onChangeStaff: () => void;
  isCheckIn?: boolean;
};
const Service = ({ name, price, onClearService, staff, onChangeStaff, isCheckIn }: Props) => {
  return (
    <ServiceStyled>
      <div className='group-name'>
        <Text className="name">{name}</Text>
        {isCheckIn ? null : <button type='button' className='btn-clear' onClick={onClearService}><IconClear /></button>}
      </div>
      <Flex className='group-name' align='center' style={{ alignSelf: 'stretch' }}>
        <Text style={{ flex: 1 }} className="price">{formatCurrency(price)}</Text>
        {staff && <button type='button' className="btn-choose-staff" onClick={onChangeStaff}>
          <Flex gap={'8px'} align='center' style={{ flex: 1 }} justify='flex-start'>
            <Avatar size={'default'} src={staff?.avatar}>{staff?.name?.[0] || 'A'}</Avatar>
            <Text className="staff-name">{staff?.name || 'Anyone'}</Text>
          </Flex>
          <img src={iconDown} alt="iconDown" className='icon-down' />
        </button>}
      </Flex>
    </ServiceStyled>
  );
};

export default Service;

const ServiceStyled = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding: 4px 8px;
gap: 8px;

flex: 1 0 0;
border-radius: 5px;
border-radius: 5px;
background: #c5ddf86e;
border: 1px solid #148CF1;

  .name {
    flex: 1;
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
  }

  .price {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align:left;
  }

  .group-name {
    display: flex;
  }
  .btn-clear {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #CECECE;
    background: #FFF;
    &:hover {
      opacity: 0.6;
    }
  }
  .btn-choose-staff {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid #CECECE;
    background: #FFF;
    padding: 2px 4px;
    max-width: 200px;
    &:hover {
      opacity: 0.6;
    }
    .icon-down {
      height: 28px;
      width: 28px;
    } 

    .staff-name {
    	display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      color: #1D2129;
      text-overflow: ellipsis;
    }
  }
  
`;

const IconClear = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 625458">
      <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M14.6184 0.681138C14.995 1.05773 14.995 1.66829 14.6184 2.04488L2.34472 14.3186C1.96813 14.6951 1.35756 14.6951 0.980976 14.3186C0.604389 13.942 0.60439 13.3314 0.980977 12.9548L13.2547 0.681138C13.6312 0.304552 14.2418 0.304552 14.6184 0.681138Z" fill="#1D2129" />
      <path id="Vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M0.981215 0.681138C1.3578 0.304552 1.96837 0.304552 2.34496 0.681138L14.6186 12.9548C14.9952 13.3314 14.9952 13.942 14.6186 14.3186C14.242 14.6951 13.6315 14.6951 13.2549 14.3186L0.981215 2.04488C0.604628 1.66829 0.604628 1.05773 0.981215 0.681138Z" fill="#1D2129" />
    </g>
  </svg>
);
