import Nav from 'components/Nav';
import Text from 'components/Text';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import aptActions from 'services/actions/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import Calendar from '../Calendar';
import { isModeFull, switchToZoom } from 'Calendar/styles';
import { FULL_SCREEN_CLASS, FullScreenBtn } from 'components/Nav/Action';

const BookingPage = () => {
  const { shop_id } = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    switchToZoom(1);
    dispatch(aptActions.initData(shop_id || storage.shopId.get()));
  }, []);
  return (
    <PageStyled id='calendar-page'>
      <Nav />
      <Text className='spacing-div' mb={isModeFull.value ? 1 : 1.5} />
      <Calendar />
      <div className="wrapper-full-screen-btn"><FullScreenBtn/></div>
    </PageStyled>
  );
};

export default BookingPage;

const PageStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: #EAEDED;
  position: fixed;
  inset: 0;
  margin-bottom: 10px;

  .wrapper-full-screen-btn {
    display: flex;
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 99;
    width: 48px;
    height: 48px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(123, 123, 123);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }

  &.${FULL_SCREEN_CLASS} {
    zoom: 0.8;
    .NavTurnStyled, .spacing-div, .CalendarFilterStyled, 
    .AppointmentStyled .appointment-bottom-action, 
    .BlockHourItemStyled .block-hour-bottom-action, .RealtimeLineStyled {
      display: none;
    }

    .BlockHourItemStyled {
      height: 100%;
      zoom:1.2;
      .appointment-box {
        padding-bottom: 0;
      }
    }
    .AppointmentStyled {
      height: 100%;
      zoom:1.2;
      .appointment-box {
        padding-bottom: 0;
      }
    }
  }
`;
