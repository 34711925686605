import { tableLoading } from 'AppointmentList/Table';
import { calendarScrollTo } from 'Calendar';
import { ANYONE_ID } from 'Calendar/anyone';
import calcTo from 'Calendar/helper';
import { ICalendarItemData } from 'Calendar/types';
import { dateParamSignal } from 'CalendarFilter/DatePicker';
import { AxiosResponse } from 'axios';
import { loadingSignal } from 'components/Loading';
import moment from 'moment';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'store/actionConfigs';
import { IAppointmentItemData } from 'types/appointment';
import { ICategoryUI } from 'types/category';
import { ICustomerUI } from 'types/customer';
import { IServiceUI } from 'types/service';
import { IStaffUI } from 'types/staff';
import storage from 'utils/sessionStorage';
import toast from 'utils/toast';
import aptActions from './actions/apt';
import shopActions from './actions/shop';
import apis from './apis';
import { tableSelectors } from './selectors/apt';
import { ICalendarDropData } from './types/apt';
import { IAppointmentListParams, IPayloadBooking, IPayloadBookingV2, IPayloadDragADrop } from './types/request';
import { IAppointmentResItem, IAptListResPayload, IBookingAptResData, ICategoryResItem, ICustomerResItem, ISchedulerSalonResData, IServiceResItem, IStaffResItem } from './types/response';
import { IBlockHourItemData, IBlockHourResItem, IBodyAddBlockHours } from './types/blockHours';
import { IBodyApiQuickBooking, IQuickBookingServiceItem } from './types/quickBooking';
import { first, get } from 'lodash';
import aptSignal from 'AppointmentBox/signal';
import qbSignal from 'QuickBooking/signal';
import { ADD_APPOINTMENT_TABS } from 'AppointmentBox/helper';
import { modalConfirmSMSChangeRef } from 'AppointmentBox/ModalConfirmSMSChange';
import { blockHourTableLoading } from 'AppointmentList/Table/BlockHourTable';
import turnActions from 'Turn/services/actions';
import commActions from './actions/comm';
import { IApiGetQuickCommissionsParams } from './types/comm';
import commSelectors from './selectors/comm';
import { commUIActions } from './reducers/comm';

const mappingAppointment = (appointmentRes: IAppointmentResItem[]) => appointmentRes.map(o => {
  const startTime = moment(o.start_time, 'MM/DD/YYYY HH:mm A');
  let endTime = moment(o.end_time, 'MM/DD/YYYY HH:mm A');
  if (o.start_time === o.end_time) {
    endTime = startTime.clone().add(15, 'minute');
  }

  return ({
    id: o.id?.toString(),
    type: 'apt',
    data: {
      id: o.id?.toString(),
      customerId: o.customerId?.toString(),
      customerName: o.customerName,
      customerPhone: o.customerPhone,
      services: o.services.map(s => ({
        duration: s.duration,
        serviceId: s.id.toString(),
        serviceName: s.serviceName,
      })),
      sourceType: o.bookingType,
      staffId: o.staffId?.toString(),
      startTime: startTime.format('MM-DD-YYYY HH:mm:ss'),
      endTime: endTime.format('MM-DD-YYYY HH:mm:ss'),
      note: o.note || '',
      status: o.status,
      isAnybody: !!o.isAnybody,
      requestStaff: o.requestStaff ?? false,
      confirmed: o.strStatus === 'CONFIRMED' || o.strStatus === 'CHECK_IN',
    } as IAppointmentItemData,
    staffId: (!o.staffId || o.staffId === 0) ? ANYONE_ID : o.staffId.toString(),
    startTime: startTime.format('MM-DD-YYYY HH:mm:ss'),
    endTime: endTime.format('MM-DD-YYYY HH:mm:ss'),
  }) as ICalendarItemData;
});

const getCalendarData: ISagaFunc<{ shopId?: string, startTime: string }> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const res: AxiosResponse<{ payload: { data: IAppointmentResItem[] } }> = yield call(apis.getAppointments, payload.shopId || storage.shopId.get(), payload.startTime);
    const appointmentRes = res?.data?.payload?.data;
    if (appointmentRes) {
      const data: ICalendarItemData[] = mappingAppointment(appointmentRes);
      yield put(aptActions.calendar.getData.success(data));
    }
  } catch (error) {

  } finally {
    loadingSignal.value = false;
  }
};

const mappingBlockHour = (blockHourItems: IBlockHourResItem[]) => {
  const data: ICalendarItemData[] = blockHourItems.map(o => {
    const id = o.id?.toString();
    const staffId = o.staffId?.toString();
    const startTime = moment(o.startTime, 'MM/DD/YYYY HH:mm A').format('MM-DD-YYYY HH:mm:ss');
    const endTime = moment(o.endTime, 'MM/DD/YYYY HH:mm A').format('MM-DD-YYYY HH:mm:ss');
    return ({
      id,
      type: 'block-hour',
      data: {
        id,
        staffId, startTime, endTime,
        note: o.reason || '',
      } as IBlockHourItemData,
      staffId, startTime, endTime,
    });
  });
  return data;
};

const getCalendarBlockHourList: ISagaFunc<{ shopId?: string, startTime: string }> = function* ({ payload }) {
  try {
    const res: AxiosResponse<{ payload: IBlockHourResItem[] }> = yield call(apis.getAllBlockHoursAptList, payload.shopId || storage.shopId.get(), {
      startTime: payload.startTime,
      endTime: payload.startTime,
    });
    const blockHourItems = res?.data?.payload;
    if (blockHourItems) {
      yield put(aptActions.calendar.setBlockHoursData(mappingBlockHour(blockHourItems)));
    }
  } catch (error) {

  } finally {
  }
};

const getCategories = function* (shopId: string) {
  try {
    const { cate, ser } = yield all({ cate: call(apis.getCategories, shopId), ser: call(apis.getServices, shopId) });

    const categoriesRes: ICategoryResItem[] = cate?.data?.payload;
    const servicesRes: IServiceResItem[] = ser?.data?.payload;
    if (categoriesRes && servicesRes) {
      const data: ICategoryUI[] = categoriesRes.map((o) => ({
        id: o.id?.toString(),
        name: o.categoryName,
        services: servicesRes.filter(s => s.catId === o.id).map(s => ({
          cateId: o.id?.toString(),
          id: s.id?.toString(),
          image: s.urlImage,
          name: s.serviceName,
          duration: s.duration,
          price: s.price,
        }) as IServiceUI),
      }));
      yield put(shopActions.category.success(data));
    }
  } catch (error) {

  }
};

const getStaffs = function* (shopId: string) {
  try {
    const res: AxiosResponse<{ payload: IStaffResItem[] }> = yield call(apis.getStaffs, shopId);
    const staffRes = res?.data?.payload;
    if (staffRes) {
      const data: IStaffUI[] = staffRes.map(o => ({
        avatar: o.urlImage,
        extraData: o,
        id: o.id?.toString(),
        name: o.firstName,
      }));
      yield put(shopActions.staff.success(data));
    }
  } catch (error) {

  }
};

const getStaffsAvailable: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: AxiosResponse<{ payload: IStaffResItem[] }> = yield call(apis.getStaffsAvailable, storage.shopId.get(), payload || dateParamSignal.value?.format('YYYY-MM-DD') || '');
    const staffRes = res?.data?.payload;
    if (staffRes) {
      const data: IStaffUI[] = staffRes.map(o => ({
        avatar: o.urlImage,
        extraData: o,
        id: o.id?.toString(),
        name: o.firstName,
      }));
      yield put(shopActions.staffAvailable.success(data));
    }
  } catch (error) {
  }
};

const getStaffActions = function* () {
  yield getStaffs(storage.shopId.get());
};

const getCustomers = function* (shopId: string) {
  try {
    const res: AxiosResponse<{ payload: { customers: ICustomerResItem[] } }> = yield call(apis.getAllCustomerAutoSearchPage, shopId, '');

    const customerRes = res?.data?.payload?.customers;
    if (customerRes) {
      const data: ICustomerUI[] = customerRes.map(o => ({
        id: o.id?.toString(),
        name: o.name,
        phone: o.phone,
      }));
      yield put(shopActions.customer.success(data));
    }

  } catch (error) {

  }
};

const getSalonInfo = function* (shopId: string) {
  try {
    const res: AxiosResponse<{ payload: any }> = yield call(apis.getSalonInfo, shopId);
    if (res.data.payload) {
      yield put(shopActions.salonInfo.success(res.data.payload));
    }
  } catch (error) { }
};

const initData: ISagaFunc<string> = function* ({ payload }) {
  loadingSignal.value = true;
  yield delay(200);
  yield storage.shopId.set(payload);
  yield all([
    getCategories(payload),
    getStaffs(payload),
    getSalonInfo(payload),
    put(shopActions.staffAvailable.fetch(dateParamSignal.value?.format('YYYY-MM-DD') || '')),
    getCustomers(payload),
    put(aptActions.quickBooking.getServices.fetch(payload)),
    put(aptActions.calendar.getData.fetch({ shopId: payload, startTime: dateParamSignal.value?.format('YYYY-MM-DD') || '' })),
    put(aptActions.table.setParams({})),
    getSalonScheduler(),
  ]);

  loadingSignal.value = false;
};

const getAptList = function* () {
  const res: AxiosResponse<{ payload: { data: IAppointmentResItem[] } }> = yield call(apis.getAppointments, storage.shopId.get(), dateParamSignal.value?.format('YYYY-MM-DD') || '');
  const appointmentRes = res?.data?.payload?.data;
  if (appointmentRes) {
    const data: ICalendarItemData[] = mappingAppointment(appointmentRes);
    yield put(aptActions.calendar.getData.success(data));
  }
};
const getBlockHourList = function* () {
  const res: AxiosResponse<{ payload: IBlockHourResItem[] }> = yield call(apis.getAllBlockHoursAptList, storage.shopId.get(), {
    startTime: dateParamSignal.value?.format('YYYY-MM-DD') || '',
    endTime: dateParamSignal.value?.format('YYYY-MM-DD') || '',
  });
  const blockHourItems = res?.data?.payload;
  if (blockHourItems) {
    const data: ICalendarItemData[] = mappingBlockHour(blockHourItems);
    yield put(aptActions.calendar.setBlockHoursData(data));
  }
};

const bookApt: ISagaFunc<IPayloadBooking> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: IBookingAptResData }> = yield call(apis.bookAppointment, storage.shopId.get(), payload);
    if (apt.data.payload) {
      dateParamSignal.value = moment(payload.payload.startTime, 'MM/DD/YYYY HH:mm');
      aptSignal.close();
      setTimeout(() => {
        const id = dateParamSignal?.value?.format('HH:mm') + '/' + (payload.payload.staffId || ANYONE_ID);
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 1000);
      yield getAptList();
    }
  } catch (error: any) {
    const errorCode = get(error, 'response.data.code');
    if (errorCode === 1170) {
      aptSignal.tabSignal.value = ADD_APPOINTMENT_TABS.TIME;
    }
  }
  finally {
    loadingSignal.value = false;
  }
};

const bookAptV2: ISagaFunc<IPayloadBookingV2> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: IBookingAptResData }> = yield call(apis.bookAppointmentV2, storage.shopId.get(), payload);
    const firstItem = first(payload.items);
    if (apt.data.payload) {
      dateParamSignal.value = moment(firstItem?.startTime || '', 'MM/DD/YYYY HH:mm');
      aptSignal.close();
      setTimeout(() => {
        const id = dateParamSignal?.value?.format('HH:mm') + '/' + (firstItem?.staffId || ANYONE_ID);
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 1000);
      yield getAptList();
    }
  } catch (error: any) {
    const errorCode = get(error, 'response.data.code');
    if (errorCode === 1170) {
      aptSignal.tabSignal.value = ADD_APPOINTMENT_TABS.TIME;
    }
  }
  finally {
    loadingSignal.value = false;
  }
};
const addBlockHour: ISagaFunc<IBodyAddBlockHours> = function* ({ payload }) {
  loadingSignal.value = true;

  try {
    const apt: AxiosResponse<{ payload: any }> = yield call(apis.addBlockHours, payload);
    if (apt.data.payload) {
      dateParamSignal.value = moment(payload.startTime, 'MM-DD-YYYY HH:mm:ss');
      setTimeout(() => {
        const id = dateParamSignal?.value?.format('HH:mm') + '/' + (payload.staffId || ANYONE_ID);
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 1000);
      yield getBlockHourList();
    }
  } catch (error) {
    toast.error('An error occurred, please try again!');
  }
  finally {
    loadingSignal.value = false;
  }
};

const deleteApt: ISagaFunc<{ aptId: string, pinCode: string }> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: string }> = yield call(apis.deleteApt, storage.shopId.get(), payload.aptId, payload.pinCode);
    if (apt.data.payload) {
      yield getAptList();
      aptSignal.close();
    }
  } catch (error) {
    toast.error('An error occurred, please try again!');
  }
  finally {
    loadingSignal.value = false;
  }
};

const cancelApt: ISagaFunc<{ aptId: string, reason: string, pinCode: string }> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: string }> = yield call(apis.cancelApt, storage.shopId.get(), payload);
    if (apt.data.payload) {
      yield getAptList();
      aptSignal.close();
    }
  } catch (error) { }
  finally {
    loadingSignal.value = false;
  }
};
const confirmApt: ISagaFunc<{ appointmentId: string, pinCode: string }> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: string }> = yield call(apis.confirmApt, storage.shopId.get(), payload);
    if (apt.data.payload) {
      yield getAptList();
      aptSignal.close();
    }
  } catch (error) { }
  finally {
    loadingSignal.value = false;
  }
};

const checkInApt: ISagaFunc<string> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: string }> = yield call(apis.checkInApt, storage.shopId.get(), payload);
    if (apt.data.payload) {
      yield getAptList();
      yield put(turnActions.data.listStaffs.fetch());
      aptSignal.close();
    }
  } catch (error) { }
  finally {
    loadingSignal.value = false;
  }
};

const deleteBlockHour: ISagaFunc<string> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const res = yield call(apis.removeBlockHour, payload);
    if (res.data.payload) {
      yield getBlockHourList();
    }
  } catch (error) { }
  finally {
    loadingSignal.value = false;
  }
};

const updateApt: ISagaFunc<IPayloadBooking> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: string }> = yield call(apis.updateApt, storage.shopId.get(), payload);
    if (apt.data.payload) {
      dateParamSignal.value = moment(payload.payload.startTime, 'MM/DD/YYYY HH:mm');
      aptSignal.close();
      setTimeout(() => {
        const id = dateParamSignal?.value?.format('HH:mm') + '/' + (payload.payload.staffId || ANYONE_ID);
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 1000);
      yield getAptList();
    }
  } catch (error) { }
  finally {
    loadingSignal.value = false;
  }
};

const updateBlockHour: ISagaFunc<IBodyAddBlockHours> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: string }> = yield call(apis.updateBlockHour, storage.shopId.get(), payload);
    if (apt.data.payload) {
      dateParamSignal.value = moment(payload.startTime, 'MM/DD/YYYY HH:mm');
      setTimeout(() => {
        const id = dateParamSignal?.value?.format('HH:mm') + '/' + (payload.staffId || ANYONE_ID);
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 1000);
      yield getBlockHourList();
    }
  } catch (error) {
    toast.error('An error occurred, please try again!');
  }
  finally {
    loadingSignal.value = false;
  }
};

const calendarUpdateDrop: ISagaFunc<ICalendarDropData> = function* ({ payload }) {
  loadingSignal.value = true;
  const startTime = moment(payload.startTime, 'MM-DD-YYYY HH:mm:ss');
  const body: IPayloadDragADrop = {
    payload: {
      appointmentId: +payload.id,
      startTime: startTime.format('MM-DD-YYYY HH:mm:ss'),
      endTime: startTime.clone().add(payload.distance, 'minutes').format('MM-DD-YYYY HH:mm:ss'),
      staffId: +(payload.staffId || ''),
      pinCode: payload.pinCode,
    }
  };
  try {
    const res: AxiosResponse<{ payload: boolean }> = yield call(apis.updateDragAndDrop, storage.shopId.get(), body);
    if (res.data.payload) {
      modalConfirmSMSChangeRef.current?.open(payload.id);
    }

  } catch (error) {
    toast.error('An error occurred, please try again!');
  }
  finally {
    loadingSignal.value = false;
    yield getAptList();
  }
};

const getAppointmentLists: ISagaFunc<IAppointmentListParams> = function* ({ payload }) {
  tableLoading.value = true;
  try {
    const res: AxiosResponse<{ payload: IAptListResPayload }> = yield call(apis.getAppointmentLists, storage.shopId.get(), payload);
    if (res?.data?.payload) {
      yield put(aptActions.table.getData.success(res.data.payload));
    }
  } catch (error) { }
  finally {
    tableLoading.value = false;
  }
};
const getAptListBlockHourList: ISagaFunc<IAppointmentListParams> = function* ({ payload }) {
  blockHourTableLoading.value = true;
  try {
    const res: AxiosResponse<{ payload: IBlockHourResItem[] }> = yield call(apis.getAllBlockHoursAptList, storage.shopId.get(), {
      endTime: payload.end_time || '',
      staffId: payload.staffId || '',
      startTime: payload.start_time || '',
    });
    const blockHourItems = res?.data?.payload;
    if (blockHourItems) {
      yield put(aptActions.table.blockHours.success(blockHourItems));
    }
  } catch (error) {
  } finally {
    blockHourTableLoading.value = false;
  }
};
const setTableData: ISagaFunc<IAppointmentListParams> = function* ({ payload }) {
  const _oldParams = yield select(tableSelectors.getPureParams);
  const params: IAppointmentListParams = {
    ..._oldParams || {},
    ...payload || {},
  };
  if (params.staffId === undefined) {
    delete params.staffId;
  }
  if (params.type === -1) {
    delete params.type;
  }
  yield put(aptActions.table.getData.fetch(params));
};

const prolongedTime: ISagaFunc<{ id: string, status: 'ADD' | 'MINUS', pinCode: string }> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    yield call(apis.prolongedTime, payload.id, payload.status, payload.pinCode);
  } catch (error) { }
  finally {
    yield getAptList();
    loadingSignal.value = false;
  }
};

const getStaffAvailableBooking: ISagaFunc<{ startTime: string, endTime: string }> = function* ({ payload }) {
  try {
    const res: AxiosResponse<{ payload: IStaffResItem[] }> = yield call(apis.getStaffAvailableBooking, payload);
    if (res.data.payload) {
      const data: IStaffUI[] = res.data.payload.map(o => ({
        avatar: o.urlImage,
        extraData: o,
        id: o.id?.toString(),
        name: o.firstName,
      }));
      yield put(aptActions.staffAvailableBooking.success(data));
    }
  } catch (error) {
    yield put(aptActions.staffAvailableBooking.fail({}));
  }
};

const prolongedTimeBlockHour: ISagaFunc<{ id: string, status: 'ADD' | 'MINUS' }> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    yield call(apis.prolongedTimeBlockHour, payload.id, payload.status);
  } catch (error) { }
  finally {
    yield getBlockHourList();
    loadingSignal.value = false;
  }
};

const getSalonScheduler = function* () {
  try {
    const res: AxiosResponse<{ payload: ISchedulerSalonResData }> = yield call(apis.getSalonScheduler);
    if (res.data.payload) {
      yield put(shopActions.scheduler.success(res.data.payload));
    }
  } catch (error) {
    yield put(shopActions.scheduler.fail({}));
  }
};

const refreshCalendar = function* () {
  loadingSignal.value = true;
  yield getAptList();
  const pixel = calcTo(moment().format('MM-DD-YYYY HH:mm:ss'));
  calendarScrollTo.value(pixel - 10);
  loadingSignal.value = false;
};

const quickBookingGetServices: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: AxiosResponse<{ payload: IQuickBookingServiceItem[] }> = yield call(apis.getQuickBookingServices, payload);
    if (res.data.payload) {
      yield put(aptActions.quickBooking.getServices.success(res.data.payload));
    }
  } catch (error) { }
};

const doQuickBooking: ISagaFunc<IBodyApiQuickBooking[]> = function* ({ payload }) {
  loadingSignal.value = true;
  try {
    const apt: AxiosResponse<{ payload: IBookingAptResData }> = yield call(apis.quickBooking, storage.shopId.get(), payload);
    if (apt.data.payload) {
      qbSignal.close();
      const firstItem = first(payload);
      dateParamSignal.value = moment(firstItem?.startTime, 'MM/DD/YYYY HH:mm');
      setTimeout(() => {
        const id = dateParamSignal?.value?.format('HH:mm') + '/' + (firstItem?.staffId || ANYONE_ID);
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 1000);
      yield getAptList();
    }
  } catch (error) { }
  finally {
    loadingSignal.value = false;
  }
};

const setParamGetCommissionList: ISagaFunc<IApiGetQuickCommissionsParams> = function* ({ payload }) {
  const _oldParams: IApiGetQuickCommissionsParams = yield select(commSelectors.getPureParams);
  const params: IApiGetQuickCommissionsParams = {
    ..._oldParams || {},
    ...payload || {},
    pageSize: 25,
  };

  if (params.staffId === '' || params.staffId === undefined) {
    delete params.staffId;
  }

  yield put(commActions.getQuickCommissions.fetch(params));
};

const getQuickCommissions = (paging?: boolean): ISagaFunc<IApiGetQuickCommissionsParams> => function* ({ payload }) {
  yield put(commUIActions.setLoadingTable(true));
  try {
    const res: AxiosResponse<{ payload: any }> = yield call(apis.getQuickCommissions, payload);
    if (res.data.payload) {
      yield put(commActions.getQuickCommissions.success(res.data.payload));
    }
  } catch (error) { }
  finally {
    yield put(commUIActions.setLoadingTable(false));
  }
};

const initQuickCommissions: ISagaFunc<IApiGetQuickCommissionsParams> = function* ({ payload }) {
  yield put(commUIActions.setLoadingTable(true));
  try {
    const res: AxiosResponse<{ payload: any }> = yield call(apis.getQuickCommissions, { ...payload, pageSize: 25 });
    if (res.data.payload) {
      yield put(commActions.getQuickCommissions.success(res.data.payload));
    }
  } catch (error) { }
  finally {
    yield put(commUIActions.setLoadingTable(false));
  }
};

const getOwnerReportCommissions = function* () {
  const _oldParams: IApiGetQuickCommissionsParams = yield select(commSelectors.getPureParams);
  const fromDate = moment(_oldParams.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
  const toDate = moment(_oldParams.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD');

  try {
    const res: AxiosResponse<{ payload: any }> = yield call(apis.getOwnerReportCommissions, fromDate, toDate);
    if (res.data.payload) {
      console.log('res.data.payload', res.data.payload);
      yield put(commActions.getOwnerReportCommissions.success(res.data.payload));
    }
  } catch (error) {
  }
};

export default function* aptSagas() {
  yield takeLatest(aptActions.calendar.getData.fetch, getCalendarData);
  yield takeLatest(aptActions.calendar.refresh, refreshCalendar);
  yield takeLatest(aptActions.initData, initData);
  yield takeLatest(aptActions.calendar.updateDrop.fetch, calendarUpdateDrop);
  yield takeLatest(aptActions.bookApt, bookApt);
  yield takeLatest(aptActions.bookAptV2, bookAptV2);
  yield takeLatest(aptActions.deleteApt, deleteApt);
  yield takeLatest(aptActions.cancelApt, cancelApt);
  yield takeLatest(aptActions.confirmApt, confirmApt);
  yield takeLatest(aptActions.checkInApt, checkInApt);
  yield takeLatest(aptActions.updateApt, updateApt);
  yield takeLatest(aptActions.table.getData.fetch, getAppointmentLists);
  yield takeLatest(aptActions.table.getData.fetch, getAptListBlockHourList);
  yield takeLatest(aptActions.table.setParams, setTableData);
  yield takeLatest(aptActions.calendar.prolongedTime, prolongedTime);
  yield takeLatest(aptActions.calendar.getData.fetch, getCalendarBlockHourList);
  yield takeLatest(aptActions.deleteBlockHour, deleteBlockHour);
  yield takeLatest(aptActions.addBlockHour, addBlockHour);
  yield takeLatest(aptActions.updateBlockHour, updateBlockHour);
  yield takeLatest(aptActions.prolongedTimeBlockHour, prolongedTimeBlockHour);
  yield takeLatest(aptActions.quickBooking.getServices.fetch, quickBookingGetServices);
  yield takeLatest(aptActions.quickBooking.doQuickBooking, doQuickBooking);
  yield takeLatest(shopActions.staff.fetch, getStaffActions);
  yield takeLatest(shopActions.staffAvailable.fetch, getStaffsAvailable);
  yield takeLatest(shopActions.scheduler.fetch, getSalonScheduler);
  yield takeLatest(aptActions.staffAvailableBooking.fetch, getStaffAvailableBooking);
  yield takeLatest(commActions.setParams, setParamGetCommissionList);
  yield takeLatest(commActions.init, initQuickCommissions);
  yield takeLatest(commActions.getQuickCommissions.fetch, getQuickCommissions());
  yield takeLatest(commActions.getQuickCommissions.fetchPaging, getQuickCommissions(true));
  yield takeLatest(commActions.getOwnerReportCommissions.fetch, getOwnerReportCommissions);
}