import { CALENDAR_TIME_SET } from 'constants/calendar';
import moment, { Moment } from 'moment';

const getCurrentTimeSlot = (date?: Moment) => {
  const filterDate = date || moment();
  const now = moment().add(15, 'minute');
  const nowTime = [0, 15, 30, 45, 60].map(o => ({ key: o, value: (now.get('minute') - o) })).filter(o => o.value >= 0);
  const minute = nowTime.find(o => o.value === Math.min(...nowTime.map(o => o.value)))?.key;

  let startTime = filterDate.clone();
  if (filterDate.isSame(now, 'date')) {
    startTime = moment().set({
      hour: now.get('hour'),
      minute: minute ?? now.get('minute'),
      second: 0,
    });
    const { close, open } = CALENDAR_TIME_SET;
    if (startTime.isBetween(open, close))
      return startTime;
  }
  return startTime.set({ hour: 9, minute: 0, second: 0 });
};

export default getCurrentTimeSlot;
