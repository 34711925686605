import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

function ReceiptReviewWrapperLayout() {
  // return <Outlet/>;
  return (
    <Container>
      <BoxReceiptContainer>
        <Outlet />
      </BoxReceiptContainer>
    </Container>
  );
}

export default ReceiptReviewWrapperLayout;

const Container = styled.div`
background: #F6F8FC;
min-height: 100vh;
padding: 32px 0;
display: flex;
align-items: center;
justify-content: center;
`;

const BoxReceiptContainer = styled.div`
display: flex;
width: 324px;
flex-direction: column;
align-items: center;
gap: 24px;
border-radius: 5px;
background: #FFF;
`;