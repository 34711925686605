import turnServiceReducer from 'Turn/services/reducers';
import aptReducer from 'services/reducers/apt';
import commServiceReducer from 'services/reducers/comm';
import shopReducer from 'services/reducers/shop';

const rootReducer = {
  apt: aptReducer,
  shop: shopReducer,
  turn: turnServiceReducer,
  comm: commServiceReducer,
};

export default rootReducer;
