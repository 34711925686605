import { Avatar, Flex, Form } from 'antd';
import Text from 'components/Text';
import { find } from 'lodash';
import ModalStaff, { useModalStaffRef } from 'QuickBooking/ModalStaff';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import aptActions from 'services/actions/apt';
import aptSelectors from 'services/selectors/apt';
import shopSelectors from 'services/selectors/shop';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import { maskPhone } from 'utils/formatPhone';
import uuid from 'utils/uuid';
import Modal from './Modal';
import iconDown from './ReviewTab/icons/down.svg';
import aptSignal from './signal';

type Ref = {
  open: (totalPeople: number) => void;
  close: () => void;
};
export const useModalConfirmGroupRef = () => useRef<Ref>(null);
type Props = {
  onOk: (staffAssigns: IStaffUI[]) => void;
  onCancel?: () => void;
};
type IStaffAssignItem = {
  uuid: string;
  staff: IStaffUI;
};

const ModalConfirmGroup = forwardRef<Ref, Props>(({ onOk, onCancel = () => undefined }, ref) => {
  const dispatch = useAppDispatch();
  const staffPicker = useModalStaffRef();
  const [visible, setVisible] = useState(false);
  const staffOrder = shopSelectors.staff();
  const _staffs = aptSelectors.staffAvailableBooking();
  const loading = aptSelectors.staffAvailableBookingLoading();
  const allServices = shopSelectors.allServices();

  const staffs = useMemo(() => {
    const list: IStaffUI[] = [];
    staffOrder.map(o => {
      const item = _staffs.find(s => s.id === o.id);
      if (item) {
        list.push(item);
      }
    });
    return list;
  }, [_staffs, staffOrder]);

  const servicesText = useMemo(() => {
    const _services: string[] = [];
    aptSignal.servicesSignal?.value.map(o => {
      const item = find(allServices, s => s.id === o.serviceId);
      if (!item) return;
      _services.push(item.name);
    });
    return _services.join(', ');
  }, [aptSignal.servicesSignal?.value]);

  const time = useMemo(() => {
    const values = aptSignal.getValues();
    const startTime = values.time?.format('hh:mm A') || '';
    const endTime = values.time?.clone().add(values.totalDuration, 'minute')?.format('hh:mm A') || '';
    return `${startTime} - ${endTime}`;
  }, [aptSignal.timeSignal.value, aptSignal.totalDurationSignal.value, aptSignal.totalDurationSignalDefault.value]);

  const customer = useMemo(() => {
    if (!aptSignal.customerSignal.value) return '';
    return `${aptSignal.customerSignal.value.name} - ${maskPhone(aptSignal.customerSignal.value.phone)}`;
  }, [aptSignal.customerSignal.value]);

  const [form] = Form.useForm();
  const onOpen: Ref['open'] = (totalPeople) => {
    if (!totalPeople) return;
    setVisible(true);
    form.setFieldValue('totalPeople', totalPeople);
    form.setFieldValue('staffAssigns', staffs.slice(0, totalPeople).map(o => ({
      uuid: uuid(),
      staff: o,
    })));
  };

  const onClose = () => {
    setVisible(false);
    onCancel();
  };
  const handleOk = () => {
    const staffAssigns: IStaffAssignItem[] = form.getFieldValue('staffAssigns');
    onOk(staffAssigns.map(o => o.staff));
    setVisible(false);
  };

  const handleOnChangeStaff = (onChange: (staff: IStaffUI) => void) => {
    const values = aptSignal.getValues();
    const startTime = values.time?.format('MM-DD-YYYY HH:mm') || '';
    const endTime = values.time?.clone().add(values.totalDuration, 'minute')?.format('MM-DD-YYYY HH:mm') || '';
    dispatch(aptActions.staffAvailableBooking.fetch({ startTime, endTime }));
    staffPicker.current?.openCallback(onChange);
  };

  useImperativeHandle(ref, () => ({
    open: onOpen,
    close: onClose,
  }));

  return (
    <Modal cancelText='BACK' okText='BOOK APPOINTMENT' open={visible} title="ASSIGN STAFF" onCancel={onClose} onOk={handleOk} width='1200px' height='auto'>
      <Container form={form}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            return <div className='title-section'>Group {getFieldValue('totalPeople')} People</div>;
          }}
        </Form.Item>
        <Flex gap={'16px'} align='center'>
          <div className='title-section'>Time: {aptSignal.timeSignal.value?.format('MM-DD-YYYY')} </div>
          <div className='title-section'>{time}</div>
        </Flex>
        <Form.List name={'staffAssigns'}>
          {(fields) =>
            <StaffAssignsStyled>
              {fields.map(o => (
                <Form.Item key={o.key} name={o.name}>
                  <StaffAssignItem onChangeStaff={handleOnChangeStaff} servicesText={servicesText} time={time} customer={customer} />
                </Form.Item>
              ))}
            </StaffAssignsStyled>
          }
        </Form.List>
      </Container>
      <ModalStaff loading={loading} title='CHOOSE STAFF' ref={staffPicker} staffList={staffs} />
    </Modal>
  );
});
ModalConfirmGroup.displayName = 'ModalConfirmGroup';
export default ModalConfirmGroup;


const Container = styled(Form)`
  padding: 0 16px;
  padding-top: 16px;
  .title-section {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
}
`;

const StaffAssignItem = ({ value, time, servicesText, customer, onChange, onChangeStaff }: { value?: IStaffAssignItem, servicesText: string, time: string, customer: string, onChange?: (value: IStaffAssignItem) => void, onChangeStaff: (onChange: (staff: IStaffUI) => void) => void; }) => {
  const handleOnChangeStaff = () => {
    if (!value || !onChange) return;
    onChangeStaff(staff => onChange({ ...value, staff }));
  };
  return <StaffAssignItemStyled>
    <div className='time-appointment'>{time}</div>
    <div className='customer-appointment'>{customer}</div>
    <div className='services-appointment'>Ser: {servicesText}</div>
    <Flex gap={'8px'} align='center' style={{ width: '100%', marginTop: '24px' }} justify='space-between'>
      <div className='customer-appointment' style={{ flex: 1 }} >Staff:</div>
      <button type='button' className="btn-choose-staff" onClick={handleOnChangeStaff}>
        <Flex gap={'8px'} align='center' style={{ flex: 1 }} justify='flex-start'>
          <Avatar size={'default'} src={value?.staff?.avatar}>{value?.staff?.name?.[0] || 'A'}</Avatar>
          <Text className="staff-name">{value?.staff?.name || 'Anyone'}</Text>
        </Flex>
        <img src={iconDown} alt="iconDown" className='icon-down' />
      </button>
    </Flex>
  </StaffAssignItemStyled>;
};

const StaffAssignsStyled = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;
const StaffAssignItemStyled = styled.div`
border-radius: 5px;
overflow: hidden;

display: flex;
flex-direction: column;
justify-content: space-between;
padding: 4px 8px;
flex: 1;
background: #fff;
border: 0.5px solid #0D446C;
box-shadow: inset 3px 0px 0px 0px #0D446C;

.time-appointment {
  color: #1D2129;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.customer-appointment {
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.services-appointment {
  color: #1D2129;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn-choose-staff {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid #CECECE;
  background: #FFF;
  padding: 2px 4px;
  max-width: 200px;
  &:hover {
    opacity: 0.6;
  }
  .icon-down {
    height: 28px;
    width: 28px;
  } 

  .staff-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
  }
}
`;
