import React from 'react';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';

const ShopInfo = () => {
  const salonInfo = shopSelectors.salonInfo();
  const address = [salonInfo?.address || '', salonInfo?.city || '', salonInfo?.state || '', salonInfo?.zip || ''].join(', ');
  return (
    <Container>
      <div className="salon-name">{salonInfo?.companyName || '-'}</div>
      <div className="salon-address">{address}<br /> {salonInfo?.phone ? formatPhone(salonInfo?.phone) : '--'}</div>
    </Container>
  );
};

export default ShopInfo;
const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 8px;
align-self: stretch;
.salon-name {
  align-self: stretch;
  color: black;
  text-align: center;
  font-family: Montserrat-800;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.2px;
}

.salon-address {
  align-self: stretch;
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
`;