import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { IApiGetQuickCommissionsParams, IQuickCommissionItem, IReportOwnerCommissionResData, IReportStaffCommissionResData } from 'services/types/comm';
import actions from '../actions/comm';

const NAME_REDUCER = 'comm';
type IState = {
  params: IApiGetQuickCommissionsParams;
  commissions: IQuickCommissionItem[];
  totalPage: number;
  totalItems: number;
  loadingTable: boolean;
  reportOwner: IReportOwnerCommissionResData | null;
  reportStaff: IReportStaffCommissionResData | null;
};
const initialState: IState = {
  params: {
    startDate: moment().format('MM-DD-YYYY'),
    endDate: moment().format('MM-DD-YYYY'),
    page: 1,
    pageSize: 25,
  },
  commissions: [],
  totalItems: 0,
  totalPage: 0,
  loadingTable: false,
  reportOwner: null,
  reportStaff: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    setLoadingTable: (state, { payload }: { payload: boolean }) => {
      state.loadingTable = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.init, (state, { payload }) => {
        const _params = {
          startDate: moment().format('MM-DD-YYYY'),
          endDate: moment().format('MM-DD-YYYY'),
          page: 1,
          ...payload,
          pageSize: 25,
        };
        if (_params.staffId === '' || _params.staffId === undefined) {
          delete _params.staffId;
        }
        state.params = _params;
      })
      .addCase(actions.getStaffReportCommissions.success, (state, { payload }) => {
        state.reportStaff = payload;
      })
      .addCase(actions.getOwnerReportCommissions.success, (state, { payload }) => {
        state.reportOwner = payload;
      })
      .addCase(actions.getQuickCommissions.success, (state, { payload }) => {
        state.commissions = payload.data;
        state.totalItems = payload.totalItems;
        state.totalPage = payload.totalPage;
      })
      .addCase(actions.setParams, (state, { payload }) => {
        const _params = {
          ...state.params,
          ...payload,
          pageSize: 25,
        };
        state.params = _params;
      })
      ;
  },
});
export const commUIActions = Slice.actions;
const commServiceReducer = Slice.reducer;
export default commServiceReducer;
