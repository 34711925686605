import { Avatar, Select } from 'antd';
import Text from 'components/Text';
import React from 'react';
import commActions from 'services/actions/comm';
import commSelectors from 'services/selectors/comm';
import shopSelectors from 'services/selectors/shop';
import { useAppDispatch } from 'store/hooks';
type Props = {
  activeStaff: { staffId: string, position: string, staffName: string; } | null
};
const StaffPicker = ({ activeStaff }: Props) => {
  const dispatch = useAppDispatch();
  const params = commSelectors.params();
  const staffs = shopSelectors.staff();

  const onChangeStaffFilter = (value: string) => {
    dispatch(commActions.setParams({ staffId: value, page: 1 }));
  };

  if (activeStaff?.position === 'Technician') return null;
  return (
    <Select onChange={onChangeStaffFilter} value={params.staffId || ''} style={{ width: 200, height: 40 }}>
      <Select.Option value={''}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Avatar size={32} >All</Avatar>
          <Text variant='CONTENT_2'>All staff</Text>
        </div>
      </Select.Option>
      {staffs?.map(o => (
        <Select.Option key={o.id} value={o.id}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Avatar size={32} src={o.avatar} >{o.name?.[0] || 'A'}</Avatar>
            <Text variant='CONTENT_2'>{o.name}</Text>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default StaffPicker;
