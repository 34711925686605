import { sumBy } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import ShopInfo from 'Receipts/components/ShopInfo';
import commSelectors from 'services/selectors/comm';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  billRef?: React.RefObject<HTMLDivElement>;
};
const QuickCommissionReceiptStaff = ({ billRef }: Props) => {
  const params = commSelectors.params();
  const data = commSelectors.reportStaff();
  const date = useMemo(() => {
    const dateMoment = moment(params.startDate, 'MM-DD-YYYY');
    if (dateMoment.isValid()) return dateMoment.format('ddd, MM-DD-YYYY hh:mm A');
    return '';
  }, [params]);
  return (
    <Container ref={billRef}>
      <CreatedDate>{date}</CreatedDate>
      <ShopInfo />
      <div className="staff-name-title">REPORT {data?.staffName || ''}</div>
      <table>
        <thead>
          <tr className='ticket-thead'>
            <th className='align-left'>TICKETS</th>
            <th>PRICE</th>
            <th className='align-right'>TIPS</th>
          </tr>
          <tr className='divider'><td colSpan={3}></td></tr>
        </thead>
        <tbody>
          {data?.tickets.map(o => (
            <React.Fragment key={o.billId}>
              <tr className='spacing'><td colSpan={3}></td></tr>
              <tr className='ticket-service-item'>
                <th className='align-left'>#{o.ticketNumber} - {o.time}</th>
                <th>{formatCurrency(o.sale)}</th>
                <th className='align-right'>{formatCurrency(o.tip)}</th>
              </tr>
            </React.Fragment>

          ))}
        </tbody>
        <tfoot>
          <tr className='spacing'><td colSpan={3}></td></tr>
          <tr className='divider'><td colSpan={3}></td></tr>
          <tr className='total-row'>
            <th className='align-left'>TOTAL</th>
            <th>{formatCurrency(sumBy(data?.tickets, o => o.sale))}</th>
            <th className='align-right'>{formatCurrency(sumBy(data?.tickets, o => o.tip))}</th>
          </tr>
          <tr className="sub-details-row">
            <td className='align-left' colSpan={2}>Discounts</td>
            <td className='align-right'>{formatCurrency(sumBy(data?.tickets, o => o.discount))}</td>
          </tr>
          <tr className="sub-details-row">
            <td className='align-left' colSpan={2}>Tickets</td>
            <td className='align-right'>{data?.tickets?.length || 0}</td>
          </tr>
          {/* <tr className="sub-details-row">
            <td className='align-left' colSpan={2}>Appointment</td>
            <td className='align-right'>{formatCurrency(sumBy(data?.tickets, o => o.))}</td>
          </tr> */}
          <tr className='spacing'><td colSpan={3}></td></tr>
          <tr className='divider'><td colSpan={3}></td></tr>
        </tfoot>
      </table>
      <Footer>
        <div className="ticket-total">
          <span>Tickets</span>
          <span className="value">{data?.tickets?.length || 0}</span>
        </div>
        <div className="contact-note">Please contact manager if you have any problem. Thank you</div>
      </Footer>
    </Container>
  );
};

export default QuickCommissionReceiptStaff;

const Container = styled.div`
  display: flex;
  padding: 24px 16px;
  padding-top: 0;
  flex-direction: column;
  align-items: center;
  .staff-name-title {
    margin: 8px 0;
    align-self: stretch;
    color: black;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    th, td {
      text-align: center;
      font-size: 14px;
      color: black;
      font-family: Poppins;
      font-style: normal;
    }
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
    .align-center {
      text-align: center;
    }

    tr.divider td{
        height: 1px;
        border-top: 1px solid black;
    }
    tr.spacing td {
        height: 4px;
    }

    tr.ticket-thead {
      background: black;
      td, th {
        color: white;
      }
    }

    tr.total-row {
      background: black;
      th {
        color: white;
      }
    }
  }
`;

const CreatedDate = styled.p`
color: black;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
margin-bottom: 0px;
`;

const Footer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 0px;
align-self: stretch;
.ticket-total {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  span {
    color: black;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.value {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.contact-note {
  align-self: stretch;
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
`;