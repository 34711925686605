import React, { useMemo } from 'react';
import styled from 'styled-components';
import SalesOwner from './sale.owner';
import PaymentOwner from './payment.owner';
import EmployeesOwner from './employees.owner';
import commSelectors from 'services/selectors/comm';
import shopSelectors from 'services/selectors/shop';
import moment from 'moment';
type Props = {
  billRef?: React.RefObject<HTMLDivElement>;
};
const QuickCommissionReceiptOwner = ({ billRef }: Props) => {
  const data = commSelectors.reportOwner();
  const shopInfo = shopSelectors.salonInfo();
  const params = commSelectors.params();
  const date = useMemo(() => {
    const { startDate, endDate } = params || {};
    if (startDate === endDate) return startDate;
    const startDateMoment = moment(startDate, 'MM-DD-YYYY');
    const endDateMoment = moment(endDate, 'MM-DD-YYYY');
    return `${startDateMoment.format('MMM DD')} - ${endDateMoment.format('MMM DD, YYYY')}`;
  }, [params]);
  return (
    <Container ref={billRef}>
      <div className="receipt-top">
        <div className="salon-name">{shopInfo?.companyName || '--'}</div>
        <div className="receipt-date">{date}</div>
      </div>
      <ReportDetails>
        <SalesOwner data={data} />
        <PaymentOwner data={data} />
        <br/>
        <EmployeesOwner data={data} />
      </ReportDetails>
    </Container>
  );
};

export default QuickCommissionReceiptOwner;

const ReportDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;
const Container = styled.div`
  display: flex;
  padding: 24px 16px;
  padding-top: 0;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  .receipt-top {
    display: flex;
    padding-bottom: 4px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid black;
  }

  .salon-name {
    align-self: stretch;
    color: black;
    text-align: center;
    font-family: Montserrat-800;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.2px;
  }

  .receipt-date {
    align-self: stretch;
    color: black;
    text-align: center;
    font-family: Montserrat-800;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.7px;
  }

  .section-title {
    align-self: stretch;
    color: white;
    background: black;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;

