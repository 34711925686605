
import quick_commission_icon from './quick-commission.svg';

export const IconBlockHour = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9993 12.0402C10.7556 12.0402 9.74736 13.0484 9.74736 14.292C9.74736 15.5357 10.7556 16.5439 11.9993 16.5439C13.2429 16.5439 14.2512 15.5357 14.2512 14.292C14.2512 13.0484 13.2429 12.0402 11.9993 12.0402ZM8.24609 14.292C8.24609 12.2192 9.92644 10.5389 11.9993 10.5389C14.0721 10.5389 15.7524 12.2192 15.7524 14.292C15.7524 16.3649 14.0721 18.0452 11.9993 18.0452C9.92644 18.0452 8.24609 16.3649 8.24609 14.292Z" fill="#232F3E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.35156 15.8802L13.5973 11.6423L14.6579 12.7048L10.4121 16.9427L9.35156 15.8802Z" fill="#232F3E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3 3.0405H20.9995V21.04H3V3.0405ZM4.50126 4.54177V19.5388H19.4983V4.54177H4.50126Z" fill="#232F3E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3 3.0405H20.9995V9.04556H3V3.0405ZM4.50126 4.54177V7.5443H19.4983V4.54177H4.50126Z" fill="#232F3E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.50322 6.79358H6.00195V5.29231H7.50322V6.79358Z" fill="#232F3E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4974 6.79358H9.00391V5.29231H10.4974V6.79358Z" fill="#232F3E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5013 6.79358H12V5.29231H13.5013V6.79358Z" fill="#232F3E" />
  </svg>
);

export const IconApt = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4105_7940)">
      <path d="M3 17.634C3 18.57 3.33 19.362 3.99 20.01C4.65 20.658 5.448 20.988 6.384 21H17.634C18.558 21 19.35 20.67 20.01 20.01C20.67 19.35 21 18.558 21 17.634V6.384C21 5.652 20.79 4.998 20.37 4.422C19.95 3.846 19.41 3.444 18.75 3.216V4.692C18.75 5.16 18.588 5.562 18.264 5.898C17.94 6.234 17.538 6.396 17.058 6.384C16.578 6.372 16.182 6.21 15.87 5.898C15.558 5.586 15.396 5.184 15.384 4.692V3H8.634V4.692C8.634 5.16 8.466 5.562 8.13 5.898C7.794 6.234 7.398 6.396 6.942 6.384C6.486 6.372 6.084 6.21 5.736 5.898C5.388 5.586 5.226 5.184 5.25 4.692V3.216C4.59 3.456 4.05 3.858 3.63 4.422C3.21 4.986 3 5.64 3 6.384L3 17.634ZM5.25 17.634V8.634H18.75V17.634C18.75 17.946 18.642 18.21 18.426 18.426C18.21 18.642 17.946 18.75 17.634 18.75H6.384C6.072 18.75 5.802 18.642 5.574 18.426C5.346 18.21 5.238 17.946 5.25 17.634ZM6.384 4.692C6.384 4.848 6.438 4.98 6.546 5.088C6.654 5.196 6.786 5.25 6.942 5.25C7.098 5.25 7.23 5.196 7.338 5.088C7.446 4.98 7.5 4.848 7.5 4.692V3H6.384V4.692ZM7.5 16.5H9.75V14.25H7.5V16.5ZM7.5 13.134H9.75V10.884H7.5V13.134ZM10.884 16.5H13.134V14.25H10.884V16.5ZM10.884 13.134H13.134V10.884H10.884V13.134ZM14.25 16.5H16.5V14.25H14.25V16.5ZM14.25 13.134H16.5V10.884H14.25V13.134ZM16.5 4.692C16.5 4.848 16.554 4.98 16.662 5.088C16.77 5.196 16.902 5.25 17.058 5.25C17.214 5.25 17.346 5.196 17.454 5.088C17.562 4.98 17.622 4.848 17.634 4.692V3H16.5V4.692Z" fill="#232F3E" />
    </g>
    <defs>
      <clipPath id="clip0_4105_7940">
        <rect width="18" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);

export const IconNewApt = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 9H21M12 18V12M15 15.001L9 15M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#232F3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const RefreshIcon = ({ fill }: { fill?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.41799 6.32391C7.59544 5.12701 9.04939 4.38841 10.5702 4.1052L10.1908 2C8.26318 2.35897 6.41836 3.29678 4.92921 4.81053C1.0236 8.78064 1.0236 15.2175 4.92921 19.1876L3.13208 21.0144H8.21511V15.8474L6.41799 17.6742C3.33462 14.5399 3.33462 9.4582 6.41799 6.32391ZM20.8679 2.98563H15.7849V8.1526L17.582 6.3258C20.6654 9.4601 20.6654 14.5418 17.582 17.6761C16.4046 18.873 14.9506 19.6116 13.4298 19.8948L13.8092 22C15.7368 21.641 17.5816 20.7032 19.0708 19.1895C22.9764 15.2194 22.9764 8.78254 19.0708 4.81243L20.8679 2.98563Z" fill={fill || '#2D3043'} />
  </svg>
);
export const RefreshActiveIcon = () => <RefreshIcon fill="#232F3E" />;

export const IconQuickCommission = () => <img src={quick_commission_icon} alt="quick_commission_icon" style={{ width: 24, height: 24 }} />;
