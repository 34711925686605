import { Modal as AntModal, ModalProps, Row, Spin } from 'antd';
import ButtonConfirm from 'components/ButtonConfirm';
import Text from 'components/Text';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface Props extends ModalProps {
  disabledOk?: boolean;
  visibleOk?: boolean;
  visibleClose?: boolean;
  aptFooter?: boolean;
  updateAptFooter?: boolean;
  onDelete?: () => void;
  onUpdate?: () => void;
  onGroup?: () => void;
  onCancelApt?: () => void;
  onConfirmApt?: () => void;
  onCheckInApt?: () => void;
  confirmed?: boolean;
  checkInDone?: boolean;
  width?: string;
  height?: string;
  footerStyle?: CSSProperties,
  loading?: boolean;
}

const Modal = ({
  width = '75vw', height,
  onUpdate, onDelete,
  updateAptFooter = false,
  onCancel = () => undefined,
  onGroup,
  disabledOk, confirmed,
  onOk = () => undefined,
  onCancelApt = () => undefined,
  checkInDone = false,
  onConfirmApt = () => undefined,
  onCheckInApt = () => undefined,
  loading,
  okText = 'Next', centered = true, footerStyle, cancelText = 'CLOSE', ...rest
}: Props) => {
  return (
    <AntModal
      {...rest}
      centered={centered}
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={width}
    >
      <Header justify={'space-between'}>
        <Text className="title">{rest.title}</Text>
        {updateAptFooter && onDelete && (checkInDone ? <button onClick={onCancel} style={{ background: 'transparent', paddingLeft: '1rem', }}><p className="text-action">{'CLOSE'}</p></button> : <ButtonConfirm
          onYes={onDelete}
          renderButton={(onPress) => <button onClick={onPress} style={{ background: 'transparent', paddingLeft: '1rem', }}><p className="text-action">{'DELETE'}</p></button>}
          modalTitle='DELETE APPOINTMENT'
          message='You are about to delete the appointment?'
        />)}
      </Header>
      <ContainerContent height={height}>
        <Content height={height}>
          {rest.children}
          {updateAptFooter ? (checkInDone ? null : <>
            <Footer style={footerStyle}>
              <button className='btn btn-close' onClick={onCancel}>
                <Text className="label">{cancelText || 'CLOSE'}</Text>
              </button>
              <button className='btn btn-delete' onClick={onCancelApt}>
                <Text className="label">CANCEL</Text>
              </button>
              {
                checkInDone ? (
                  <button className='btn btn-close' disabled style={{ opacity: 0.7 }} >
                    <Text className="label">{'CHECKED-IN'}</Text>
                  </button>) : confirmed ? <button className='btn btn-confirm' onClick={onCheckInApt}>
                    <Text className="label">{'CHECK-IN'}</Text>
                  </button> :
                  <button className='btn btn-confirm' onClick={onConfirmApt}>
                    <Text className="label">{'CONFIRM'}</Text>
                  </button>
              }

              {/* <button disabled={confirmed} className='btn btn-confirm' onClick={onConfirmApt}>
                <Text className="label">{confirmed ? 'CONFIRMED' : 'CONFIRM'}</Text>
              </button> */}
              <button className='btn btn-update' onClick={onUpdate}>
                <Text className="label">UPDATE</Text>
              </button>
            </Footer>
          </>) : <>
            <Footer style={footerStyle}>
              <button className='btn btn-close' onClick={onCancel}>
                <Text className="label">{cancelText || 'CLOSE'}</Text>
              </button>
              {onGroup && <button
                className='btn btn-confirm btn-group'
                onClick={onGroup} disabled={disabledOk}>
                <Text className="label">{'BOOK GROUP'}</Text>
              </button>}
              <button className='btn btn-update' onClick={onOk} disabled={disabledOk}>
                <Text className="label">{okText || 'NEXT'}</Text>
              </button>
            </Footer>
          </>}

        </Content>
        {loading && <div className="spinning-wrapper">
          <Spin size='large' />
        </div>}
      </ContainerContent>
    </AntModal>
  );
};

export default Modal;
const Header = styled(Row)`
  height: 50px;
  position: relative;
  align-items: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #148CF1;
  background: #E8F3FF;

  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }
  button {
    background: #fff;
    align-self: stretch;
    border-radius: 5px;
    width: 80px;
    
    &:hover {
      background: #FFEFEB;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  .text-action {
    color: #148CF1;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    white-space: nowrap;
  }
`;

const ContainerContent = styled.div<{ height?: string }>`
  max-height: ${({ height }) => height || '80vh'};
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  .spinning-wrapper {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    justify-content: center;
  }
`;

const Content = styled.div<{ height?: string }>`
  height: ${({ height }) => height || '80vh'};
  display: flex;
  flex-direction: column;
`;

const Footer = styled(Row)`
position: sticky;
padding: 0.75rem 1.5rem;
margin-top: 1rem;
gap: 1rem;
bottom: 0;
right: 0;
left: 0;
background-color: #F6F6F6;
.btn {
  display: flex;
  height: 48px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  .label {
    text-align: center;

    /* Body 1 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  
}
  .btn-delete, .btn-close {
    border-radius: 2px;
    border: 1px solid #7B7B7B;
    background: #7B7B7B;
    .label {
      color: #fff;
    }
  }

  .btn-update {
    border-radius: 2px;
    background: #232F3E;
    .label {
      color: #FFF;
    }
  }
  .btn-delete {
    background: #fff;
    .label {
      color: #7B7B7B;
    }
  }
  .btn-confirm {
    border-radius: 2px;
    background: #0085E7;
    .label {
      color: #FFF;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
`;