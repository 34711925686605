import { signal } from '@preact/signals-react';
import { AxiosResponse } from 'axios';
import { IDatePickerValue } from 'components/DatePicker';
import { first } from 'lodash';
import moment, { Moment } from 'moment';
import { ModalChooseStaffRef } from 'QuickBooking/ModalStaff';
import React from 'react';
import apis from 'services/apis';
import { IBlockHourItemData, IBodyAddBlockHours } from 'services/types/blockHours';
import { IStaffUI } from 'types/staff';
import getCurrentTimeSlot from 'utils/getCurrentTimeSlot';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import { IInputSearchStaffRef } from 'widgets/Staff/InputSearchAvatar';
import { ALL_DAY_FLAT, FORMAT_DATE_PAYLOAD, timeLogOpts } from './helpers';

const openSignal = signal<boolean>(false);
const activeDateSignal = signal<IDatePickerValue | null>(moment());
const listTimeSlotSignal = signal<string[]>([]);
const durationSignal = signal<number>(15);
const staffIdSignal = signal<string>('');
const startTimeSlot = signal<string>('');
const reasonSignal = signal<string>('');
const detailSignal = signal<IBlockHourItemData | null>(null);

const errorStaffSignal = signal<string>('');
const errorReasonSignal = signal<string>('');
const errorTimeSlotSignal = signal<string>('');
const timeSlotLoadingSignal = signal<boolean>(false);

export const blockHourChooseStaffRef = React.createRef<ModalChooseStaffRef>();
export const blockHourInputSearchStaffRef = React.createRef<IInputSearchStaffRef>();

const fetchTimeSlot = async (staffId: string, date: string) => {
  timeSlotLoadingSignal.value = true;
  const dateMoment = moment(date, 'YYYY-MM-DD');
  try {
    const res: AxiosResponse<{ payload: { timeSlots: string[] } }> = await apis.getTimeSlot(storage.shopId.get(), date || '', staffId || '');
    const timeSlots = res?.data?.payload?.timeSlots;
    if (dateMoment.isSame(moment(), 'date')) {
      const now = getCurrentTimeSlot();
      const data = timeSlots?.filter(o => moment(o, 'hh:mm A') >= now) || [];
      return data || [];
    }
    return timeSlots;
  } catch (error) {
    return [];
  } finally {
    timeSlotLoadingSignal.value = false;
  }
};

const onChangeActiveDate = async (value: IDatePickerValue | null) => {
  activeDateSignal.value = value;
  listTimeSlotSignal.value = [];
  durationSignal.value = 15;
  const timeSlots = await fetchTimeSlot(staffIdSignal.value || '', value?.format('YYYY-MM-DD') || '');
  startTimeSlot.value = first(timeSlots) || '';
  listTimeSlotSignal.value = timeSlots;
};
const onSelectStaff = (data: IStaffUI) => {
  errorStaffSignal.value = '';
  staffIdSignal.value = data.id?.toString();
};
const onChangeDuration = (data: number) => {
  durationSignal.value = data;
};
const onChangeStartTime = (data: string) => {
  errorTimeSlotSignal.value = '';
  startTimeSlot.value = data;
};
const onChangeReason = (data: string) => {
  errorReasonSignal.value = '';
  reasonSignal.value = data;
};

const bhSignal = {
  openSignal,
  activeDateSignal, onChangeActiveDate,
  listTimeSlotSignal,
  staffIdSignal, onSelectStaff,
  startTimeSlot, onChangeStartTime,
  reasonSignal, onChangeReason,
  durationSignal, onChangeDuration,
  errorStaffSignal,
  errorReasonSignal,
  errorTimeSlotSignal,
  detailSignal,
  timeSlotLoadingSignal,
  open: (dateStr: string) => {
    staffIdSignal.value = '';
    reasonSignal.value = '';
    durationSignal.value = timeLogOpts?.[0]?.value || 15;
    detailSignal.value = null;
    const dateMoment = moment(dateStr);
    activeDateSignal.value = dateMoment;
    errorTimeSlotSignal.value = '';
    blockHourChooseStaffRef.current?.openCallback(async (staff) => {
      openSignal.value = true;
      staffIdSignal.value = staff.id;
      const listTimeSlot = await fetchTimeSlot(staff.id, dateMoment.format('YYYY-MM-DD'));
      listTimeSlotSignal.value = listTimeSlot;
      startTimeSlot.value = listTimeSlot.find(o => o === dateMoment?.format('hh:mm A')) || first(listTimeSlot) || '';
    });
  },
  openNew: async (staffId: string, _startTime: string) => {
    openSignal.value = true;
    const startTime = moment(_startTime);
    activeDateSignal.value = moment(startTime);
    listTimeSlotSignal.value = [];

    startTimeSlot.value = startTime?.format('hh:mm A');

    staffIdSignal.value = staffId;

    const listTimeSlot = await fetchTimeSlot(staffId, startTime?.format('YYYY-MM-DD'));
    listTimeSlotSignal.value = listTimeSlot;
    startTimeSlot.value = listTimeSlot.find(o => o === startTime?.format('hh:mm A')) || first(listTimeSlot) || '';

    reasonSignal.value = '';
    durationSignal.value = timeLogOpts?.[0]?.value || 15;
    detailSignal.value = null;
  },
  openDetail: (detail: IBlockHourItemData) => {

    detailSignal.value = detail;
    openSignal.value = true;
    staffIdSignal.value = detail.staffId;
    reasonSignal.value = detail.note;

    const startTime = momentTimezone(detail.startTime);
    const endTime = momentTimezone(detail.endTime);

    activeDateSignal.value = startTime;
    listTimeSlotSignal.value = [];

    startTimeSlot.value = startTime.format('hh:mm A');
    const duration = endTime.diff(startTime, 'minutes');
    durationSignal.value = duration > 240 ? ALL_DAY_FLAT : duration;
  },
  close: () => {
    openSignal.value = false;
  },
  getPayload: () => {
    if (!staffIdSignal.value) {
      errorStaffSignal.value = 'Please select staff';
    }
    if (!reasonSignal.value) {
      errorReasonSignal.value = 'Please enter your reason';
    }
    if (!startTimeSlot.value) {
      errorTimeSlotSignal.value = 'Please choose start time';
    }
    if (!reasonSignal.value || !staffIdSignal.value) return null;
    if (!activeDateSignal.value) return null;
    if (!startTimeSlot.value) return null;
    if (!durationSignal.value) return null;

    const startDateMoment = moment(`${activeDateSignal.value?.format('MM-DD-YYYY')} ${startTimeSlot.value}`, 'MM-DD-YYYY hh:mm A');
    let endDateMoment: Moment | null = null;
    if (durationSignal.value === ALL_DAY_FLAT) {
      startDateMoment.set({ hour: 9, minute: 0, second: 0 });
      endDateMoment = startDateMoment.clone().set({ hour: 21, minute: 0, second: 0 });
    } else {
      endDateMoment = startDateMoment.clone().add(durationSignal.value, 'minute');
    }
    if (!endDateMoment) return null;


    const payload: IBodyAddBlockHours = {
      staffId: +staffIdSignal.value,
      endTime: endDateMoment.format(FORMAT_DATE_PAYLOAD),
      startTime: startDateMoment.format(FORMAT_DATE_PAYLOAD),
      reason: reasonSignal.value,
    };
    return payload;
  },
};

export default bhSignal;
