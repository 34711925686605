import styled from 'styled-components';
import empty_icon from './icons/empty.svg';

const ContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  img.empty-icon {
    display: flex;
    width: 120px;
    height: 121px;
    padding: 2px 0px 2.039px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .empty-text {
    color: #7B7B7B;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const emptyLocaleTable = {
  emptyText: <ContainerEmpty>
    <img src={empty_icon} alt="empty_icon" className="empty-icon" />
    <span className="empty-text">Empty</span>
  </ContainerEmpty>
};