import { Modal as AntModal, Avatar, Input, Spin } from 'antd';
import { ContainerContentModal, HeaderModal } from 'components/Modal';
import Text from 'components/Text';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
export type ModalChooseStaffRef = {
  open: (extraData?: any) => void;
  openCallback: (callBack: (staff: IStaffUI) => void) => void;
  close: () => void;
};
export const useModalStaffRef = () => useRef<ModalChooseStaffRef>(null);
type Props = {
  selected?: string[];
  onChange?: (data: IStaffUI, extraData?: any) => void;
  staffList: IStaffUI[];
  title?: string;
  loading?: boolean;
};
const ModalStaff = forwardRef<ModalChooseStaffRef, Props>(({ selected = [], onChange = () => undefined, staffList: _staffList = [], title, loading = false }, ref) => {
  const staffList = useMemo(() => _staffList.filter(o => !selected.includes(o.id)), [_staffList, selected]);
  const [extraData, setExtraData] = useState<IStaffUI | null>(null);
  const [visible, setVisible] = useState(false);
  const handleClose = () => setVisible(false);
  const callbackRef = useRef<(staff: IStaffUI) => void>(() => undefined);

  const onChooseStaff = (o: IStaffUI) => () => {
    onChange(o, extraData);
    callbackRef.current(o);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    close: () => setVisible(false),
    open: (_extraData) => {
      setVisible(true);
      setExtraData(_extraData);
    },
    openCallback(callBack) {
      setVisible(true);
      callbackRef.current = callBack;
    },
  }));

  return (
    <AntModal
      open={visible}
      onCancel={handleClose}
      okText={'Booking'}
      centered
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={'982px'}
    >
      <HeaderModal title={title || 'CHANGE STAFF'} onCancel={handleClose} />
      <Spin spinning={loading}>
        <Container height='40vh'>
          <div>
            <Input
              prefix={<IconSearch />}
              placeholder="Staff Name"
            />
          </div>
          <div className="staff-list">
            {staffList.map(o => (
              <button key={o.id} type='button' onClick={onChooseStaff(o)}>
                <StaffItem data={o} />
              </button>
            ))}
          </div>
        </Container>
      </Spin>
    </AntModal>
  );
});

export default ModalStaff;

const Container = styled(ContainerContentModal)`
  background: #F6F6F6;
  flex: 1;
  border-radius: 5px;
  padding: 24px;
  gap: 12px;
  .staff-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 12px;
  }
`;

type StaffItemProps = {
  data: IStaffUI;
};
const StaffItem = ({ data }: StaffItemProps) => {
  return (
    <StaffItemStyled>
      <Avatar src={data.avatar} >{data?.name?.[0] || ''}</Avatar>
      <Text className="name">{data.name || 'A'}</Text>
    </StaffItemStyled>
  );
};

const StaffItemStyled = styled.div`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 14px;
  background: #FFF;
  border: 1px solid #fff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  .ant-avatar {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    .ant-avatar-string {
      font-size: 2rem;
    }
    margin-bottom: 8px;
  }
 
  .name {
    overflow: hidden;
    color: #1D2129;
    text-align: center;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  &.active {
    border-radius: 14px;
    border: 1px solid #F05326;
    background: #FFEFEB;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  }
`;
const IconSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M8.21939 16.6771C9.96583 16.6771 11.5966 16.1089 12.9222 15.1621L17.909 20.1489C18.1405 20.3804 18.4456 20.4961 18.7612 20.4961C19.4451 20.4961 19.9395 19.9701 19.9395 19.2967C19.9395 18.9811 19.8343 18.6865 19.6029 18.4551L14.6476 13.4893C15.6891 12.1216 16.3099 10.4277 16.3099 8.58657C16.3099 4.13628 12.6697 0.496094 8.21939 0.496094C3.75857 0.496094 0.128906 4.13628 0.128906 8.58657C0.128906 13.0369 3.75857 16.6771 8.21939 16.6771ZM8.21939 14.9306C4.73701 14.9306 1.87536 12.0584 1.87536 8.58657C1.87536 5.11472 4.73701 2.24254 8.21939 2.24254C11.6912 2.24254 14.5634 5.11472 14.5634 8.58657C14.5634 12.0584 11.6912 14.9306 8.21939 14.9306Z" fill="#1D2129" />
  </svg>
);
