import { createAction } from '@reduxjs/toolkit';
import { ICalendarItemData } from 'Calendar/types';
import { ICalendarDropData, IStateCalendar } from 'services/types/apt';
import { IBlockHourResItem, IBodyAddBlockHours } from 'services/types/blockHours';
import { IBodyApiQuickBooking, IQuickBookingServiceItem } from 'services/types/quickBooking';
import { IAppointmentListParams, IPayloadBooking, IPayloadBookingV2 } from 'services/types/request';
import { IAptListResPayload } from 'services/types/response';
import { createAsyncAction } from 'store/actionConfigs';

const PREFIX_ACTIONS = 'appointment_feature_';

const calendar = {
  setDistanceTimeline: createAction<IStateCalendar['distanceTimeLine']>(PREFIX_ACTIONS + '_configs_' + 'setDistanceTimeline'),
  setTypeView: createAction<IStateCalendar['typeView']>(PREFIX_ACTIONS + '_calendar_' + 'setTypeView'),
  setDateRanges: createAction<IStateCalendar['dateRanges']>(PREFIX_ACTIONS + '_calendar_' + 'setDateRanges'),
  updateDrop: createAsyncAction<ICalendarDropData>(PREFIX_ACTIONS + '_calendar_' + 'updateDrop'),
  getData: createAsyncAction<{ shopId?: string, startTime: string }>(PREFIX_ACTIONS + '_calendar_' + 'getData'),
  prolongedTime: createAction<{ id: string, status: 'ADD' | 'MINUS', pinCode: string }>(PREFIX_ACTIONS + '_calendar_' + 'prolongedTime'),
  refresh: createAction(PREFIX_ACTIONS + '_calendar_' + 'refresh'),
  setBlockHoursData: createAction<ICalendarItemData[]>(PREFIX_ACTIONS + 'setBlockHoursData'),
};

const table = {
  getData: createAsyncAction<IAppointmentListParams, IAptListResPayload>(PREFIX_ACTIONS + '_table_' + 'getData'),
  blockHours: createAsyncAction<IAppointmentListParams, IBlockHourResItem[]>(PREFIX_ACTIONS + '_table_' + 'getBlockHours'),
  setParams: createAction<IAppointmentListParams>(PREFIX_ACTIONS + '_table_' + 'setParams'),
};

const initData = createAction<string>(PREFIX_ACTIONS + 'initData');

const bookApt = createAction<IPayloadBooking>(PREFIX_ACTIONS + 'bookApt');

const bookAptV2 = createAction<IPayloadBookingV2>(PREFIX_ACTIONS + 'bookAptV2');

const deleteApt = createAction<{ aptId: string, pinCode: string }>(PREFIX_ACTIONS + 'deleteApt');

const cancelApt = createAction<{ aptId: string, reason: string, pinCode: string }>(PREFIX_ACTIONS + 'cancelApt');

const confirmApt = createAction<{ appointmentId: string, pinCode: string }>(PREFIX_ACTIONS + 'confirmApt');

const checkInApt = createAction<string>(PREFIX_ACTIONS + 'checkInApt');

const updateBlockHour = createAction<IBodyAddBlockHours>(PREFIX_ACTIONS + 'updateBlockHour');

const addBlockHour = createAction<IBodyAddBlockHours>(PREFIX_ACTIONS + 'addBlockHour');

const deleteBlockHour = createAction<string>(PREFIX_ACTIONS + 'deleteBlockHour');

const prolongedTimeBlockHour = createAction<{ id: string, status: 'ADD' | 'MINUS' }>(PREFIX_ACTIONS + 'prolongedTimeBlockHour');

const updateApt = createAction<IPayloadBooking>(PREFIX_ACTIONS + 'updateApt');

const quickBooking = {
  getServices: createAsyncAction<string, IQuickBookingServiceItem[]>(PREFIX_ACTIONS + 'Quick-Booking' + 'getServices'),
  doQuickBooking: createAction<IBodyApiQuickBooking[]>(PREFIX_ACTIONS + 'doQuickBooking'),
};

const staffAvailableBooking = createAsyncAction<{ startTime: string, endTime: string }>(PREFIX_ACTIONS + 'getStaffAvailableBooking');

const aptActions = {
  initData,
  calendar,
  table,
  bookApt,
  bookAptV2,
  deleteApt,
  updateApt,
  addBlockHour,
  deleteBlockHour,
  updateBlockHour,
  prolongedTimeBlockHour,
  quickBooking,
  cancelApt,
  confirmApt,
  staffAvailableBooking,
  checkInApt,
};

export default aptActions;

