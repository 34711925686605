import { computed } from '@preact/signals-react';
import TabLabel from 'AppointmentBox/TabLabel';
import { IDatePickerValue } from 'AppointmentBox/TimeTab/DatePicker';
import { ADD_APPOINTMENT_TABS } from 'AppointmentBox/helper';
import aptSignal, { totalDurationSignal } from 'AppointmentBox/signal';
import { Avatar, Checkbox, Flex, InputRef, Row } from 'antd';
import Text from 'components/Text';
import { find, remove, sumBy } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import { ICustomerUI } from 'types/customer';
import { IServiceUI } from 'types/service';
import { IStaffUI } from 'types/staff';
import { formatPhone, maskPhone } from 'utils/formatPhone';
import InputNote from './InputNote';
import { APPOINTMENT_GROUP_VALUE } from './RadioGroup';
import Service from './Service';
import EditCustomer from 'AppointmentBox/CustomerTab/EditCustomer';
import ModalStaff, { useModalStaffRef } from 'QuickBooking/ModalStaff';
import { useAppDispatch } from 'store/hooks';
import aptActions from 'services/actions/apt';
import aptSelectors from 'services/selectors/apt';
import CustomerTab from 'AppointmentBox/CustomerTab';
import { AppointmentStatus } from 'Turn/services/constants';

const Label = () => {
  const activeKey = computed<string>(() => aptSignal.tabSignal.value).value;

  return (
    <TabLabel
      label='Review'
      tabIndex={5}
      completed={aptSignal.getValid()}
      active={activeKey === ADD_APPOINTMENT_TABS.REVIEW}
    />
  );
};

type IServiceMappingData = { data: IServiceUI, staffId: string, staffData: IStaffUI | null, startTime: string, endTime: string; };

export type ReviewTabProps = {
  customer: ICustomerUI | null,
  staff: IStaffUI | null,
  time: IDatePickerValue,
  services: IServiceUI[],
};

const Children = () => {
  const dispatch = useAppDispatch();
  const staffModalRef = useModalStaffRef();
  const inputGroupRef = useRef<InputRef>(null);
  const groupTab = computed(() => aptSignal.groupTabSignal.value).value;
  const customer = computed(() => aptSignal.customerSignal.value).value;
  const staffs = shopSelectors.staff();
  const _staffs = aptSelectors.staffAvailableBooking();
  const staffAvailableBookingLoading = aptSelectors.staffAvailableBookingLoading();
  const isCheckIn = aptSignal.detailStatusSignal.value === AppointmentStatus.CHECK_IN;

  const staffList = useMemo(() => {
    const list: IStaffUI[] = [];
    staffs.map(o => {
      const item = _staffs.find(s => s.id === o.id);
      if (item) {
        list.push(item);
      }
    });
    return list;
  }, [_staffs, staffs]);

  const staff = computed<IStaffUI | null>(() => {
    return staffs.find(o => o.id === aptSignal.staffIdSignal.value) || null;
  }).value;
  const time = useMemo(() => {
    const _time = aptSignal.timeSignal.value;
    return aptSignal.activeDateSignal.value?.clone().set({
      hour: _time?.get('hour'),
      minute: _time?.get('minute'),
      second: _time?.get('second'),
    });
  }, [aptSignal.activeDateSignal.value, aptSignal.timeSignal.value]);

  useEffect(() => {
    if (groupTab !== APPOINTMENT_GROUP_VALUE.GROUP) return;
    setTimeout(() => inputGroupRef.current?.focus(), 100);
  }, [groupTab]);

  const allServices = shopSelectors.allServices();

  const services = computed(() => {
    const sers: IServiceMappingData[] = [];
    const startTimeTemp = aptSignal.timeSignal.value?.clone();
    aptSignal.servicesSignal?.value?.map(o => {
      const item = find(allServices, s => s.id === o.serviceId);
      if (item) sers.push({
        data: item,
        staffId: o.staffId,
        staffData: staffs.find(sta => sta.id === o.staffId) || null,
        startTime: startTimeTemp?.format('MM-DD-YYYY HH:mm') || '',
        endTime: startTimeTemp?.add(o.duration, 'minute').format('MM-DD-YYYY HH:mm') || '',
      });
    });
    return sers;
  }).value;

  useEffect(() => {
    totalDurationSignal.value = sumBy(services, o => o.data.duration);
  }, [services]);

  const handleAfterEditCus = (o: ICustomerUI) => {
    aptSignal.customerSignal.value = o;
  };

  const onChangeStaffService = (o: IServiceMappingData) => () => {
    dispatch(aptActions.staffAvailableBooking.fetch({
      startTime: o.startTime,
      endTime: o.endTime,
    }));
    staffModalRef.current?.openCallback(_staff => {
      aptSignal.servicesSignal.value = aptSignal.servicesSignal?.value.map(s => {
        if (s.serviceId === o.data.id) {
          return ({
            ...s,
            staffId: _staff.id,
          });
        }
        return s;
      });
    });
  };

  const onClearService = (o: IServiceUI) => () => {
    const temp = [...aptSignal.servicesSignal?.value || []];
    if (aptSignal.servicesSignal?.value?.find(s => s.serviceId === o.id)) {
      remove(temp, s => s.serviceId === o.id);
    } else {
      temp.push({
        serviceId: o.id,
        staffId: aptSignal.staffIdSignal.value || '',
        name: o.name,
        duration: o.duration,
      });
    }
    aptSignal.servicesSignal.value = temp;
  };

  return (
    <ReviewTabStyled>
      <Container vertical gap={8}>
        {/* <Row>
          <RadioGroup
            value={groupTab}
            onChange={tab => {
              aptSignal.groupTabSignal.value = tab;
            }}
          />
          <Row style={{ opacity: groupTab === APPOINTMENT_GROUP_VALUE.GROUP ? 1 : 0, pointerEvents: groupTab === APPOINTMENT_GROUP_VALUE.GROUP ? undefined : 'none' }}>
            <Input
              ref={inputGroupRef}
              size='large'
              value={aptSignal.groupValueSignal.value || ''}
              style={{ lineHeight: 2, marginLeft: 10, width: '8rem', textAlign: 'center' }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={e => {
                aptSignal.groupValueSignal.value = e.target.value;
                const numbers = (+e.target.value);
                if (numbers > 0) {
                  aptSignal.noteSignal.value = `Group: ${numbers} ${services.map(o => o.data.name).join(', ')}`;
                } else {
                  aptSignal.noteSignal.value = '';
                }
              }}
            />
          </Row>
        </Row> */}
        <Text mt={1} />
        <InputNote />
      </Container>
      <Content>
        <Row align={'middle'}>
          <ContentBox vertical>
            <SectionRow align={'middle'} style={{ height: '54px' }}>
              <SectionLabel>Customer</SectionLabel>
              {isCheckIn ? <></> : <>
                {!aptSignal.detailIdSignal.value ?
                  <ButtonChange onClick={() => aptSignal.tabSignal.value = ADD_APPOINTMENT_TABS.CUSTOMER}><EditCustomerIcon /></ButtonChange> :
                  aptSignal.customerSignal.value?.id == '0' ? <CustomerTab.WalkInModal onSelect={handleAfterEditCus} /> :
                    <EditCustomer id={aptSignal.customerSignal.value?.id || ''} onSelect={handleAfterEditCus} />
                }
              </>}
            </SectionRow>
            <div className="value-box">
              <Text className="customer-name">{customer?.name} - {customer?.phone ? maskPhone(customer?.phone) : formatPhone('00000000000')}</Text>
            </div>
          </ContentBox>
          <ContentBox vertical>
            <SectionRow align={'middle'} style={{ height: '54px' }}>
              <SectionLabel>Staff</SectionLabel>
              {isCheckIn ? <></> : <>
                <ButtonChange onClick={() => aptSignal.tabSignal.value = ADD_APPOINTMENT_TABS.STAFF}><Change /></ButtonChange>
                <Text ml={1} style={{ display: 'flex' }}>
                  <Checkbox
                    checked={aptSignal.requestStaff.value}
                    className='value-section checkbox-request-staff'
                    onChange={(e) => {
                      aptSignal.requestStaff.value = e.target.checked;
                    }}
                  >
                    Request Staff
                  </Checkbox>
                </Text>
              </>}
            </SectionRow>
            <Flex className='value-box' gap={'8px'} align='center' style={{ marginTop: '0.5rem' }}>
              <Avatar size={'large'} src={staff?.avatar}>{staff?.name?.[0] || 'A'}</Avatar>
              <Text className="staff-name">{staff?.name || 'Anyone'}</Text>
            </Flex>
          </ContentBox>
          <ContentBox vertical>
            <SectionRow align={'middle'} style={{ height: '54px' }}>
              <SectionLabel>Date and Time</SectionLabel>
              {isCheckIn ? <></> : <>
                <ButtonChange onClick={() => aptSignal.tabSignal.value = ADD_APPOINTMENT_TABS.TIME}><Change /></ButtonChange>
              </>}
            </SectionRow>
            <div className="value-box">
              <Text className="time-booking">{time?.format('MM-DD-YYYY LT')}</Text>
            </div>
          </ContentBox>
        </Row>
        <ContentBox vertical style={{ marginTop: '1rem' }}>
          <SectionRow align={'middle'}>
            <SectionLabel>Service</SectionLabel>
            {isCheckIn ? <></> : <>
              <ButtonChange onClick={() => aptSignal.tabSignal.value = ADD_APPOINTMENT_TABS.SERVICE}><Change /></ButtonChange>
            </>}
          </SectionRow>
          <Services>
            {services.map(o => (
              <ServiceWrap key={o.data.id + o.staffId}>
                <div className='box'>
                  <Service isCheckIn={isCheckIn} staff={aptSignal.detailIdSignal.value ? null : o.staffData} name={o.data.name} price={o.data.price} onClearService={onClearService(o.data)} onChangeStaff={onChangeStaffService(o)} />
                </div>
              </ServiceWrap>
            ))}
          </Services>
        </ContentBox>
      </Content>
      <ModalStaff loading={staffAvailableBookingLoading} ref={staffModalRef} staffList={staffList} />
    </ReviewTabStyled>
  );
};
const ReviewTab = {
  Label,
  Children,
};
export default ReviewTab;

const ReviewTabStyled = styled.div`
  height: 100%;
  margin-top: -16px;
  margin-bottom: 16px;
`;

const Container = styled(Flex)``;

const Content = styled.div``;


const Services = styled(Row)`
  display: flex;
  margin: -6px;
`;

const ServiceWrap = styled.div`
  width: 20%;
  .box {
    margin: 6px;
  }
`;

const ContentBox = styled(Flex)`
  flex: 1;
  align-self: stretch;
  justify-content: space-between;

  .value-box {
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 38px;
  }

  .time-booking, .customer-name {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .staff-name {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const SectionRow = styled(Row)`
  margin-bottom: 4px;
`;

const SectionLabel = styled.p`
  color: #7B7B7B;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
`;

const ButtonChange = styled.button`
  display: flex;
  height: 30px;
  width: 40px;
  margin-left: 4px;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #e9e8e8;
  }
`;

const Change = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.93056 8.92701C10.4219 7.43572 12.2633 6.51544 14.1896 6.16257L13.7091 3.53955C11.2676 3.98682 8.93103 5.15531 7.04494 7.0414C2.0983 11.988 2.0983 20.0081 7.04494 24.9548L4.7688 27.2309H11.2067V20.793L8.93056 23.0692C5.02532 19.1639 5.02532 12.8323 8.93056 8.92701ZM27.2321 4.76762H20.7942V11.2055L23.0703 8.92938C26.9756 12.8346 26.9756 19.1663 23.0703 23.0715C21.579 24.5628 19.7375 25.4831 17.8113 25.836L18.2918 28.459C20.7333 28.0117 23.0699 26.8432 24.9559 24.9571C29.9026 20.0105 29.9026 11.9904 24.9559 7.04376L27.2321 4.76762Z" fill="#148CF1" />
    </svg>
  );
};

const EditCustomerIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.33301 6.66691C4.97939 6.66691 4.64025 6.80739 4.3902 7.05743C4.14015 7.30748 3.99967 7.64662 3.99967 8.00024V26.6669C3.99967 27.0205 4.14015 27.3597 4.3902 27.6097C4.64025 27.8598 4.97939 28.0002 5.33301 28.0002H23.9997C24.3533 28.0002 24.6924 27.8598 24.9425 27.6097C25.1925 27.3597 25.333 27.0205 25.333 26.6669V21.3336C25.333 20.5972 25.93 20.0002 26.6663 20.0002C27.4027 20.0002 27.9997 20.5972 27.9997 21.3336V26.6669C27.9997 27.7278 27.5782 28.7452 26.8281 29.4953C26.078 30.2455 25.0605 30.6669 23.9997 30.6669H5.33301C4.27214 30.6669 3.25473 30.2455 2.50458 29.4953C1.75444 28.7452 1.33301 27.7278 1.33301 26.6669V8.00024C1.33301 6.93938 1.75443 5.92196 2.50458 5.17182C3.25473 4.42167 4.27214 4.00024 5.33301 4.00024H10.6663C11.4027 4.00024 11.9997 4.5972 11.9997 5.33358C11.9997 6.06996 11.4027 6.66691 10.6663 6.66691H5.33301Z" fill="#148CF1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.7902 1.72402C23.3109 1.20332 24.1551 1.20332 24.6758 1.72402L30.2758 7.32402C30.7946 7.84279 30.7968 8.68321 30.2807 9.20469L17.6141 22.0047C17.378 22.2432 17.0608 22.384 16.7255 22.3988L10.7255 22.6655C10.3466 22.6824 9.97839 22.537 9.71315 22.2658C9.44791 21.9946 9.31073 21.6233 9.33596 21.2448L9.73596 15.2448C9.75745 14.9226 9.89517 14.6191 10.1235 14.3907L22.7902 1.72402ZM23.733 4.55245L12.3634 15.9221L12.0958 19.9353L16.0857 19.758L27.4523 8.27175L23.733 4.55245Z" fill="#148CF1" />
  </svg>
);