import { Form, FormInstance, Input, InputNumber } from 'antd';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import search_icon from './icons/search.svg';
import { debounce } from 'lodash';
import uuid from 'utils/uuid';
import { QuickCommissionPaymentMethods } from './TicketTableContent';
const searchIcon = <img src={search_icon} alt="search_icon" style={{ width: 24, height: 24 }} />;

type Props = {
  value?: IStaffUI;
  onChange?: (value: IStaffUI) => void;
  form: FormInstance<any>;
  activeStaff?: {
    staffId: string;
    staffName: string;
    position: string;
  } | null;
};

const FormInputs = ({ form, activeStaff }: Props) => {
  const onClearAll = () => form.resetFields(['tickets']);
  const onAddTicket = () => {
    const numberOfTicket: number = form.getFieldValue('numberOfTicket');
    if (!numberOfTicket) return;
    const list = Array.from(Array(numberOfTicket).keys()).map(() => ({
      id: uuid(),
      ticketNumber: 1,
      paymentMethod: QuickCommissionPaymentMethods.CREDIT_CARD,
      sale: 0,
      tip: 0,
      discount: 0,
      supply: 0,
      note: '',
    }));
    const tickets = form.getFieldValue('tickets');
    const newList = [...tickets, ...list].map((o, i) => ({
      ...o,
      ticketNumber: i + 1,
    }));
    form.setFieldValue('tickets', newList);
    form.resetFields(['numberOfTicket']);
  };

  const onEnter = () => setTimeout(() => onAddTicket(), 100);

  return (
    <Container>
      <Form.Item style={{ flex: 1 }} label="Staff" name={'staffSearch'}>
        {activeStaff?.position === 'Technician' ? <Input value={activeStaff.staffName} disabled suffix={searchIcon} /> : <InputSearchStaff />}
      </Form.Item>
      <Form.Item style={{ flex: 1 }} label="Number Of Tickets" name={'numberOfTicket'}>
        <InputNumberOfTicket onEnter={onEnter} />
      </Form.Item>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <button className='btn-add' style={{ height: 48, width: 150 }} type='button' onClick={onAddTicket}>
          <span>Add Tickets</span>
        </button>
        <button className='btn-add btn-clear' style={{ height: 48, width: 150 }} type='button' onClick={onClearAll}>
          <span>Clear All</span>
        </button>
      </div>
    </Container>
  );
};

export default FormInputs;

const Container = styled.div`
  display: flex;
  padding: 0px 24px;
  align-items: flex-end;
  gap: 24px;
  align-self: stretch;
  .ant-form-item {
    margin: 0;
    .ant-input.ant-input-disabled {
      color: #1D2129;
    }
  }
  .ant-form-item-label >label {
    color: #2C333A;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .btn-add {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 8px;
    border-radius: 5px;
    background: #148CF1;
    border: 1px solid #148CF1;
    &::hover {
      background: #148CF1;
      border: 1px solid #148CF1;
      opacity: 0.8;
    }
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.btn-clear {
      border: 1px solid #86909C;
      background: #F1F1F1;
      span {
        color: #2C333A;
      }
    }
  }
`;
type InputSearchStaffProps = {
  value?: string;
  onChange?: (value: string) => void;
};
const InputSearchStaff = ({ value, onChange = () => undefined }: InputSearchStaffProps) => {
  const [text, setText] = useState(value);
  const _debounce = useCallback(debounce((_text: string) => onChange(_text), 200), [onChange]);
  const onChangeText: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value);
    _debounce(e.target.value);
  };

  return (
    <Input value={text} onChange={onChangeText} placeholder='Select staff' suffix={searchIcon} />
  );
};
type InputNumberOfTicketProps = {
  value?: number;
  onChange?: (value?: number) => void;
  onEnter?: () => void;
};
const InputNumberOfTicket = ({ value, onChange = () => undefined, onEnter = () => undefined }: InputNumberOfTicketProps) => {
  return (
    <InputNumberOfTicketStyled value={value}
      // @ts-ignore
      onChange={onChange}
      max={25}
      onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          onEnter();
          return;
        }
      }}
      placeholder='Enter Number Of Tickets' />
  );
};

const InputNumberOfTicketStyled = styled(InputNumber)`
  height: 48px;
  width: 100%;
  .ant-input-number-input-wrap {
    height: 100%;
    .ant-input-number-input {
      height: 100%; 
    }
  }
`;