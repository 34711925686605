import Modal from 'AppointmentBox/Modal';
import Text from 'components/Text';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
type Props = {};
type Ref = {
  open: (callback: () => void, title?: string, message?: string) => void;
  close: () => void;
};
export const modalConfirmRef = React.createRef<Ref>();
const ModalConfirm = forwardRef<Ref, Props>(({ }, ref) => {
  const [visible, setVisible] = useState(false);
  const callbackRef = useRef<() => void>(() => undefined);
  const handleClose = () => setVisible(false);
  const [title, setTitle] = useState('');
  const [msg, setMsg] = useState('');
  const handleOk = () => {
    callbackRef.current();
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    open: (callback, _title, _msg) => {
      callbackRef.current = callback;
      setTitle(_title || '');
      setMsg(_msg || '');
      setVisible(true);
    },
    close: () => setVisible(false),
  }));

  return (
    <Modal cancelText='NO' okText='YES' open={visible} title={title || 'Warning'} onCancel={handleClose} onOk={handleOk} width='600px' height='auto'>
      <Container>
        <Text variant='CONTENT_1' textAlign='center'>{msg || 'Are you sure?'}</Text>
      </Container>
    </Modal>
  );
});
ModalConfirm.displayName = 'ModalConfirm';
export default ModalConfirm;
export const useModalConfirmContext = (): [React.RefObject<Ref>, JSX.Element] => {
  const ref = useRef<Ref>(null);
  const context = <ModalConfirm ref={ref} />;
  return [ref, context];
};

const Container = styled.div`
  padding: 0 16px;
  padding-top: 16px;
`;